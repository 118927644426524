import Cookies from 'universal-cookie';
import {isMobile} from 'react-device-detect';
import { PROXY_URL } from "../shared/constants";


export default class BC365Service {

    _apiBase = '/api/';
    _apiVersion = 'v1';
    _useCache = true;

    getResource = async  (url, method = 'GET', data = {}, customVersion = '') => {
       
        const cookies = new Cookies();

        const userSession = cookies.get('PHPSESSID') ?? '';

        let params = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'X-BC365-Token': userSession, 
              },
        }
        if (method === 'POST') {
            params.body = JSON.stringify(data);
        };

        // For some endpoints use cache are required, so checking
        if (!url.includes('cc=') && !this._useCache) {
            const sep = url.includes('?')? '&' : '?';
            url += `${sep}cc=y`;
        }

        const apiVersion = customVersion.length > 0 ? customVersion : this._apiVersion;
        const res = await fetch(
            `${this._apiBase}${apiVersion}${url}`,
            params
        );
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, received ${res.status}`);
        }

        if (res.headers.get('Beauty-Jwt-Token') !== '') {
            cookies.set('PHPSESSID', res.headers.get('Beauty-Jwt-Token'), { path: '/'})
        }

        const body = await res.json();
        return body;
    };

    getResourceMock = async  (mock) => {
        return require('../mocks/banners');
    };

    sendWebHook = async  (action = '', data = {}) => {

        let params = {
            headers: {
                'Content-Type': 'application/json',
              },
        }
        const { email, last_name, first_name, product_id, close_reason } = data;

        let subLink = `?action=${action}&email=${email}`;
        if (typeof last_name !== undefined && last_name !== '') {
            subLink += `&last_name=${last_name}`;
        }
        if (typeof first_name !== undefined && first_name !== '') {
            subLink += `&first_name=${first_name}`;
        }
        if (typeof product_id !== undefined && product_id !== '') {
            subLink += `&product_id=${product_id}`;
        }
        if (typeof close_reason !== undefined && close_reason !== '') {
            subLink += `&close_reason=${close_reason}`;
        }
        
        const url = `https://portal.magnitmedia.ru/rest/107695/${process.env.B24_API_KEY}/magnit.setdealfrombeautyclub.json${subLink}`;
        const res = await fetch(
            url,
            params
        );
        if (!res.ok) {
            throw new Error(`Could not fetch ${url}, received ${res.status}`);
        }
        const body = await res.json();
        return body;
    };

    /**
     * 
     * Endpoints
     */
    getContent = async () => {
        const res = await this.getResource(`/react-content/`);
        return res.data;
    };

    login = async (login, password) => {
        const res = await this.getResource(`/react-login/?cc=y`, 'POST', {login, password});
        return res.data;
    };

    createSpeakerCandidate = async (fields) => {
        const res = await this.getResource(`/react-create-speaker-candidate/?cc=y`, 'POST', fields);
        return res.data;
    };

    register = async (name, lastName, email, phone, password, phoneConfirmed) => {
        const res = await this.getResource(
            `/react-register/?cc=y&name=${name}&last_name=${lastName}&login=${email}&email=${email}&phone_confirmed=${phoneConfirmed}&password=${password}&phone=${phone}`
        );
        return res.data;
    };

    isOnline = async () => {
        const res = await this.getResource('/react-is-online/?cc=y');
        return res.data;
    }

    logout = async () => {
        const res = await this.getResource('/react-logout/?cc=y');
        return res.data;
    }

    sendCode = async (phone) => {
        const res = await this.getResource(`/react-send-code/?phone=${phone}&cc=y`);
        return res.data;
    }
    
    confirmCode = async (phone, code) => {
        const res = await this.getResource(`/react-confirm-code/?phone=${phone}&code=${code}&cc=y`);
        return res.data;
    }

    forgotPassword = async (email) => {
        const res = await this.getResource(`/react-send-email/?email=${email}&cc=y`);
        return res.data;
    }

    existsByEmail = async (email) => {
        const res = await this.getResource(`/react-exists-email/?email=${email}&cc=y`);
        return res.data;
    }

    getProducts = async () => {
        const res = await this.getResource(`/react-list-products/?cc=y&test_mode=y`);
        return res.data;
    }

    getProductsByListOfIds = async (products) => {
        const res = await this.getResource(`/react-list-products-by-list/?cc=y&products=${products}`);
        return res.data;
    }

    getCertificates = async () => {
        const res = await this.getResource(`/react-list-certificates/?cc=y`);
        return res.data;
    }

    setStat = async () => {
        const res = await this.getResource(`/react-set-stat/?path=${window.location.href}&cc=y`);
        return res.data;
    }

    createOrder = async (user_id, product_id, paysystem='CLOUDPAYMENTS') => {
        const res = await this.getResource(`/react-create-order/?user_id=${user_id}&product_id=${product_id}&paysystem=${paysystem}&cc=y`);
        return res.data;
    }

    createOrderCertificate = async (user_id, product_id, name='', email='', phone='', paysystem = 'CLOUDPAYMENTS') => {
        const res = await this.getResource(`/react-create-order-certificate/?user_id=${user_id}&product_id=${product_id}&name=${name}&email=${email}&phone=${phone}&paysystem=${paysystem}&cc=y`);
        return res.data;
    }

    createOrderCommon = async (user_id, product_id, name='', email='', phone='', paysystem = 'CLOUDPAYMENTS', type='course') => {
        const res = await this.getResource(`/react-create-order-common/?user_id=${user_id}&product_id=${product_id}&name=${name}&email=${email}&phone=${phone}&paysystem=${paysystem}&type=${type}&cc=y`);
        return res.data;
    }

    getSpeakers = async (limit = 6, offset = 0, sort_by = 'SORT', sort_order = 'ASC') => {
        const res = await this.getResource(`/react-get-speakers/?limit=${limit}&offset=${offset}&sort_by=${sort_by}&sort_order=${sort_order}`);
        return res.data;
    }

    getShedule = async () => {
        const res = await this.getResource('/react-list-shedule/?cc=y');
        return res.data;
    }

    getDiscount = async (coupon, product_id, user_id) => {
        const res = await this.getResource(`/react-check-coupon/?cc=y&coupon=${coupon}&product_id=${product_id}&user_id=${user_id}`);
        return res.data;
    }

    getMarketingAction = async (key) => {
        const res = await this.getResource(`/react-marketing-action/?cc=y&key=${key}`);
        return res.data;
    }

    getVideoReviews = async () => {
      const res = await this.getResource('/react-video-reviews-list/?cc=y');
      return res.data;
    }

    canGetTrial = async () => {
        const res = await this.getResource('/can-get-trial/?cc=y');
        return res.data;
    }

    setTrial = async () => {
        const res = await this.getResource(`/set-trial/?cc=y`, 'POST', {});
        return res.data;
    };

    setTrialByUserId = async (user_id) => {
        const res = await this.getResource(`/set-trial-by-user-id/?cc=y`, 'POST', {user_id});
        return res.data;
    };

    sendEventFromPopup = async (email, type_event, product_name = '', tariff = '', phone = '', name = '') => {
        const res = await this.getResource(`/send-event-from-popup/?cc=y`, 'POST', {email, type_event, product_name, tariff, phone, name});
        return res.data;
    };

    saveLogs = async (data, type) => {
        const res = await this.getResource(`/save-logs/?cc=y`, 'POST', {data, type});
        return res.data;
    };

    getBanners = async () => {
        const res = await this.getResource('/carousel/?cc=y');
        return res.data;
    };

    getCourses = async (prepare_data='n', filter='', sort='', page='', count='') => {
        let path = `/list-courses/?cc=y`;
        if (prepare_data === 'y') {
            path += `&prepare_data=y`;
        }
        if (filter !== '') {
            path += `&filter=${filter}`;
        }
        if (sort !== '') {
            path += `&sort=${sort}`;
        }
        if (page !== '') {
            path += `&page=${page}`;
        }
        if (count !== '') {
            path += `&count=${count}`;
        }

        const res = await this.getResource(path);
        return res.data;
    };

    setGoogleMetrics = async () => {
        const res = await this.getResource(`/react-google-init/?cc=y`);
        return res.data;
    }

    getSettings = async () => {
        const res = await this.getResource(`/get-settings/?cc=y`);
        return res.data;
    }

    verifyPhoneByTelegram = async (email) => {
        const res = await this.getResource(`/verify-phone-by-telegram/?email=${email}&cc=y`);
        return res.data;
    }

    checkVerificationPhoneByTelegram = async (email) => {
        const res = await this.getResource(`/check-phone-verification-by-telegram/?email=${email}&cc=y`);
        return res.data;
    }

    sendPromoToFriend = async (email) => {
        const res = await this.getResource(`/send-promo-to-friend/?email=${email}&cc=y`);
        return res.data;
    }

    fixAnalytic = async () => {
        const res = await this.getResource(`/fix-analytic/?cc=y`, 'POST', {url: window.location.href, referer: document.referrer});
        return res.data;
    }

    /**
     * 
     * Webhooks
     */
    openPayment = async (data) => {
        /*const res = await this.sendWebHook('payment_page', data);
        return res.data;*/
    }

    startPayment = async (data) => {
        /*const res = await this.sendWebHook('payment_start', data);
        return res.data;*/
    }

    successPayment = async (data) => {
        /*const res = await this.sendWebHook('payment_success', data);
        return res.data;*/
    }

    failedPayment = async (data) => {
        /*const res = await this.sendWebHook('payment_fail', data);
        return res.data;*/
    }

    /**
     * 
     * Formattting data
     */
    getCurrentData = (content, type) => {
        const data = Object.values(content).filter((item) => {
            return item.type === type
        });
        return this.getMappedDataByCode(data, type);
    };

    getMappedData = (content, type) => {
        return this.getMappedDataByCode(content, type);
    };

    getMappedDataByCode = (data, type) => {
        if (type === 'header') {
            return data.map(this._headerContent)[0];
        }
        if (type === 'header-group') {
            return data.map(this._headerGroupContent)[0];
        }
        if (type === 'popup-free-test-drive') {
            return data.map(this._popupFreeTestDriveContent)[0];
        }
        if (type === 'club-for-you') {
            return data.map(this._clubForYouContent)[0];
        }
        if (type === 'all-for-you') {
            return data.map(this._allForYouContent)[0];
        }
        if (type === '5-days-free') {
            return data.map(this._daysFreeContent)[0];
        }
        if (type === 'success-secrets') {
            return data.map(this._successSecretsContent)[0];
        }
        if (type === 'change-your-life') {
            return data.map(this._changeYourLifeContent)[0];
        }
        if (type === 'individual-programm') {
            return data.map(this._individualProgrammContent)[0];
        }
        if (type === 'speakers') {
            return {
                result: data['data'].map(this._speakersContent),
                total: data['total'],
            }
        }
        if (type === 'shedule') {
            return this._sheduleContent(data);
        }
    };

    /**
     * 
     * Mapping props
     */

    _headerContent = (headerContent) => {
        return {
            mainText: headerContent['main-text'],
            description: headerContent.description,
            leftButtonText: headerContent['left-button-text'],
            rightButtonText: headerContent['right-button-text'],
            moreText: headerContent['more-text'],
            subMainText: headerContent['sub-main-text'],
            images: headerContent.images,
            imagesOpt: headerContent.images_opt,
            headText: headerContent['head_text'],
            headTextWithTimer: headerContent['head_text_with_timer'],
            timer: headerContent['timer'],
        };
    };

    _headerGroupContent = (headerContent) => {
        return {
            mainGroupText: headerContent['main-text'],
            listGroupText: headerContent['list-text'],
        };
    };

    _popupFreeTestDriveContent = (headerContent) => {
        return {
            popupFreeTestDriveMainText: headerContent['main-text'],
            popupFreeTestDriveSubMainText: headerContent['sub-main-text'],
            popupFreeTestDriveDescription: headerContent['description'],
            popupFreeTestDriveLeftButtonText: headerContent['left-button-text'],
            popupFreeTestDriveRightButtonText: headerContent['right-button-text'],
            popupFreeTestDriveListText: headerContent['list-text'],
        };
    };

    _clubForYouContent = (headerContent) => {
        return {
            clubForYouMainText: headerContent['main-text'],
            clubForYouListText: headerContent['list-text'],
        };
    };

    _allForYouContent = (headerContent) => {
        return {
            allForYouMainText: headerContent['main-text'],
            allForYouListText: headerContent['list-text'],
            allForYouImages: headerContent.images,
        };
    };

    _daysFreeContent = (headerContent) => {
        return {
            daysFreeText: headerContent['main-text'],
            daysFreeSubMainText: headerContent['sub-main-text'],
            daysFreeDescription: headerContent['description'],
            daysFreeMainButtonText: headerContent['main-button-text'],
            daysFreeImages: headerContent.images,
        };
    };

    _successSecretsContent = (headerContent) => {
        return {
            successSecretsMainText: headerContent['main-text'],
            successSecretsListText: headerContent['list-text'],
            successSecretsImages: headerContent.images,
            successSecretsImagesOpt: headerContent.images_opt ?? [],
        };
    };

    _changeYourLifeContent = (headerContent) => {
        return {
            changeYourLifeMainText: headerContent['main-text'],
            changeYourLifeListText: headerContent['list-text'],
            changeYourLifeImages: headerContent.images,
        };
    };

    _individualProgrammContent = (headerContent) => {
        return {
            individualProgrammMainText: headerContent['main-text'],
            individualProgrammImages: headerContent.images,
            individualProgrammImagesOpt: headerContent.images_opt ?? [],
        };
    };

    _speakersContent = (speakers) => {
        let data = {
            id: speakers['ID'],
            name: speakers['NAME'],
            previewText: speakers['PREVIEW_TEXT'],
            detailText: speakers['DETAIL_TEXT'],
            detailhoto: PROXY_URL + '/upload' + speakers['DETAIL_PICTURE'],
        }
        if (typeof speakers['PHOTO_DETAIL_OPT_VALUE'] !== 'undefined') {
            data['detailhotoOpt'] = (speakers['PHOTO_DETAIL_OPT_VALUE'] !== '') ? PROXY_URL + '/upload' + speakers['PHOTO_DETAIL_OPT_VALUE'] : '';
        } else {
            data['detailhotoOpt'] = '';
        }
        if (isMobile) {
            data['photo'] = PROXY_URL + '/upload' + speakers['PHOTO_MOBILE_VALUE'];
            if (typeof speakers['PHOTO_DETAIL_OPT_VALUE'] !== 'undefined') {
                data['photoOpt'] = (speakers['PHOTO_MOB_OPT_VALUE'] !== '') ? PROXY_URL + '/upload' + speakers['PHOTO_MOB_OPT_VALUE'] : '';
            } else {
                data['photoOpt'] = '';
            }
        } else {
            data['photo'] = PROXY_URL + '/upload' + speakers['PREVIEW_PICTURE'];
            if (typeof speakers['PHOTO_DETAIL_OPT_VALUE'] !== 'undefined') {
                data['photoOpt'] = (speakers['PHOTO_OPT_VALUE'] !== '') ? PROXY_URL + '/upload' + speakers['PHOTO_OPT_VALUE'] : '';
            } else {
                data['photoOpt'] = '';
            }
        }
        return data;
    }

    _sheduleContent = (shedule) => {
        const todayShedule = Object.values(shedule['res']['SCHEDULE'])[0];
        const todayDate = Object.keys(shedule['res']['SCHEDULE'])[0];
        const allShedule = [];
        let i = 0;
        for (const [key, value] of Object.entries(shedule['res']['SCHEDULE'])) {
            if (i > 0) {
                allShedule.push({
                    date: key,
                    content: value,
                });
            }
            i++;
        }
        return {
            todayShedule,
            todayDate,
            allShedule,
        };
    }
}
