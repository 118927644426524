import React, { useState, useEffect, useCallback } from 'react';
import Button from '../Elements/Button/Button';
import Loading from '../../Loader/Loader';
import BC365Service from '../../../services/beautyclub365';
import { useUserContext } from '../../../context/UserContext';
import './AllProducts.css';
import { Container, ListGroup, Col, Tab, Row } from 'react-bootstrap';
import Promocode from '../../Promocode/Promocode';

export default PaymentForm = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [existsError, setExistsError] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const { be } = errors;
  const { setBasket, products, defaultPrice, setDefaultPrice, userProfile } =
    useUserContext();
  const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedMainOption, setSelectedMainOption] = useState(
    `${products[1] && products[1][0].short_name}`
  );
  const [selectedSubOption, setSelectedSubOption] = useState('');

  const handleMainOptionChange = (option) => {
    setSelectedMainOption(option);
    setSelectedSubOption('');
  };

  useEffect(() => {
    setSelectedMainOption(`${products[1] && products[1][0].short_name}`);
  }, [products]);

  const handleSubOptionChange = (option) => {
    setSelectedSubOption(option);
  };

  const changePrice = useCallback(
    (e) => {
      const productId = e.target.value;
      handleSubOptionChange(productId);
      setSelectedProductId(productId);
      setIsDisabled(false);
      setDefaultPrice(e.target.getAttribute('data-price'));
      setBasket({
        price: e.target.getAttribute('data-price'),
        name: e.target.getAttribute('data-product'),
        price_clear: e.target.getAttribute('data-cprice'),
        product_id: e.target.getAttribute('data-prid'),
        payment_desc: '',
      });
      setIsDisabled(false);
    },
    [setDefaultPrice, setBasket, defaultPrice, setIsDisabled]
  );

  const db = new BC365Service();

  // Step 1. Sales funnel (open payment popup)
  useEffect(() => {
    const { fio, email } = userProfile;
    db.openPayment({
      first_name: fio,
      email,
    }).then((answer) => {
      //console.log(answer);
    });
  }, []);

  return (
    <div className="allProductsWrapper">
      {isWaitingAnswer && (
        <div className="loaderWrapper">
          <Loading width={100} />
        </div>
      )}
      {!isWaitingAnswer && (
        <>
          <div className="allProducts-container">
            <h2 className="allProductsTitle">Выберите тариф</h2>
            <h3 className="allProductsTextMobile">
              Пожалуйста, выберите необходимый тариф из выпадающего списка ниже.
            </h3>
            <div className="ProductWrapper ProductWrapper-mobile">
              <div className="frame">
                <div className="blockProductSubRadio">
                  <input
                    type="radio"
                    id={products[1] && products[1][0].short_name}
                    className="prRadio"
                    name="product"
                    checked={
                      selectedMainOption ===
                      `${products[1] && products[1][0].short_name}`
                    }
                    onChange={() =>
                      handleMainOptionChange(
                        `${products[1] && products[1][0].short_name}`
                      )
                    }
                  />
                  <label
                    htmlFor={products[1] && products[1][0].short_name}
                    className="prLabel"
                  >
                    <h2 className="allProductsFormTitle1">
                      тариф{' '}
                      <span id="green-text">
                        {products[1] && products[1][0].short_name}
                      </span>
                    </h2>
                  </label>
                </div>

                <div className="blockProductSubRadio">
                  <input
                    type="radio"
                    id={products[0] && products[0][1].short_name}
                    className="prRadio"
                    name="product"
                    checked={
                      selectedMainOption ===
                      `${products[0] && products[0][1].short_name}`
                    }
                    onChange={() =>
                      handleMainOptionChange(
                        `${products[0] && products[0][1].short_name}`
                      )
                    }
                  />
                  <label
                    htmlFor={products[0] && products[0][1].short_name}
                    className="prLabel"
                  >
                    <h2 className="allProductsFormTitle1">
                      тариф{' '}
                      <span id="pink-text">
                        {products[0] && products[0][1].short_name}
                      </span>
                    </h2>
                  </label>
                </div>

                <div className="blockProductSubRadio">
                  <input
                    type="radio"
                    id={products[0] && products[0][0].short_name}
                    className="prRadio"
                    name="product"
                    checked={
                      selectedMainOption ===
                      `${products[0] && products[0][0].short_name}`
                    }
                    onChange={() =>
                      handleMainOptionChange(
                        `${products[0] && products[0][0].short_name}`
                      )
                    }
                  />
                  <label
                    htmlFor={products[0] && products[0][0].short_name}
                    className="prLabel"
                  >
                    <h2 className="allProductsFormTitle1">
                      тариф{' '}
                      <span id="purple-text">
                        {products[0] && products[0][0].short_name}
                      </span>
                    </h2>
                  </label>
                </div>
              </div>

              {selectedMainOption ===
                `${products[1] && products[1][0].short_name}` && (
                <div className="blockProduct">
                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub1-product-' + products[1][0].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[1][0].price}
                        data-product={products[1][0].name}
                        value={products[1][0].id}
                        data-prid={products[1][0].id}
                        data-cprice={products[1][0].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[1] && products[1][0].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub1-product-' + products[1][0].id}
                        className="prLabel"
                      >
                        {products[1] && products[1][0].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[1] && products[1][0].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub1-product-' + products[2][1].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[2][1].price}
                        data-product={products[2][1].name}
                        value={products[2][1].id}
                        data-prid={products[2][1].id}
                        data-cprice={products[2][1].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[2] && products[2][1].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub1-product-' + products[2][1].id}
                        className="prLabel"
                      >
                        {products[2] && products[2][1].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[2] && products[2][1].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub1-product-' + products[4][0].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[4][0].price}
                        data-product={products[4][0].name}
                        value={products[4][0].id}
                        data-prid={products[4][0].id}
                        data-cprice={products[4][0].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[4] && products[4][0].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub1-product-' + products[4][0].id}
                        className="prLabel"
                      >
                        {products[4] && products[4][0].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[4] && products[4][0].price}</strong>
                    </span>
                  </div>
                </div>
              )}

              {selectedMainOption ===
                `${products[0] && products[0][1].short_name}` && (
                <div className="blockProduct">
                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub2-product-' + products[0][1].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[0][1].price}
                        data-product={products[0][1].name}
                        value={products[0][1].id}
                        data-prid={products[0][1].id}
                        data-cprice={products[0][1].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[0] && products[0][1].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub2-product-' + products[0][1].id}
                        className="prLabel"
                      >
                        {products[0] && products[0][1].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[0] && products[0][1].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub2-product-' + products[2][0].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[2][0].price}
                        data-product={products[2][0].name}
                        value={products[2][0].id}
                        data-prid={products[2][0].id}
                        data-cprice={products[2][0].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[2] && products[2][0].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub2-product-' + products[2][0].id}
                        className="prLabel"
                      >
                        {products[2] && products[2][0].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[2] && products[2][0].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub2-product-' + products[3][1].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[3][1].price}
                        data-product={products[3][1].name}
                        value={products[3][1].id}
                        data-prid={products[3][1].id}
                        data-cprice={products[3][1].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[3] && products[3][1].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub2-product-' + products[3][1].id}
                        className="prLabel"
                      >
                        {products[3] && products[3][1].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[3] && products[3][1].price}</strong>
                    </span>
                  </div>
                </div>
              )}

              {selectedMainOption ===
                `${products[0] && products[0][0].short_name}` && (
                <div className="blockProduct">
                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub3-product-' + products[0][0].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[0][0].price}
                        data-product={products[0][0].name}
                        value={products[0][0].id}
                        data-prid={products[0][0].id}
                        data-cprice={products[0][0].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[0] && products[0][0].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub3-product-' + products[0][0].id}
                        className="prLabel"
                      >
                        {products[0] && products[0][0].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[0] && products[0][0].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub3-product-' + products[1][1].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[1][1].price}
                        data-product={products[1][1].name}
                        value={products[1][1].id}
                        data-prid={products[1][1].id}
                        data-cprice={products[1][1].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[1] && products[1][1].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub3-product-' + products[1][1].id}
                        className="prLabel"
                      >
                        {products[1] && products[1][1].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[1] && products[1][1].price}</strong>
                    </span>
                  </div>

                  <div className="blockProductSub">
                    <div className="blockProductSubRadio">
                      <input
                        type="radio"
                        id={'sub3-product-' + products[3][0].id}
                        name="sub-product"
                        className="prRadio"
                        data-price={products[3][0].price}
                        data-product={products[3][0].name}
                        value={products[3][0].id}
                        data-prid={products[3][0].id}
                        data-cprice={products[3][0].clear_price}
                        checked={
                          selectedSubOption ===
                          `${products[3] && products[3][0].id}`
                        }
                        onChange={changePrice}
                      />
                      <label
                        htmlFor={'sub3-product-' + products[3][0].id}
                        className="prLabel"
                      >
                        {products[3] && products[3][0].period_name}
                      </label>
                    </div>
                    <span className="prPrice">
                      Цена:{' '}
                      <strong>{products[3] && products[3][0].price}</strong>
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/* «БАЗОВЫЙ» */}
            <div className="ProductWrapper ProductWrapper-hide">
              <h2 className="allProductsFormTitle">
                тариф{' '}
                <span id="green-text">
                  "{products[1] && products[1][0].short_name}"
                </span>
              </h2>
              <div className="blockProduct">
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      id={'product-' + products[1][0].id}
                      className="prRadio"
                      type="radio"
                      name="product-base"
                      data-price={products[1][0].price}
                      data-product={products[1][0].name}
                      value={products[1][0].id}
                      data-prid={products[1][0].id}
                      data-cprice={products[1][0].clear_price}
                      checked={selectedProductId === products[1][0].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[1][0].id}
                      className="prLabel"
                    >
                      {products[1] && products[1][0].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[1] && products[1][0].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[2][1].id}
                      type="radio"
                      name="product-base"
                      data-price={products[2][1].price}
                      data-product={products[2][1].name}
                      value={products[2][1].id}
                      data-prid={products[2][1].id}
                      data-cprice={products[2][1].clear_price}
                      checked={selectedProductId === products[2][1].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[2][1].id}
                      className="prLabel"
                    >
                      {products[2] && products[2][1].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[2] && products[2][1].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[4][0].id}
                      type="radio"
                      name="product-base"
                      value={products[4][0].id}
                      data-price={products[4][0].price}
                      data-product={products[4][0].name}
                      data-prid={products[4][0].id}
                      data-cprice={products[4][0].clear_price}
                      checked={selectedProductId === products[4][0].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[4][0].id}
                      className="prLabel"
                    >
                      {products[4] && products[4][0].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[4] && products[4][0].price}</strong>
                  </span>
                </div>
              </div>
            </div>

            {/* "АННЕТТ" */}
            <div className="ProductWrapper ProductWrapper-hide">
              <h2 className="allProductsFormTitle">
                тариф{' '}
                <span id="pink-text">
                  "{products[0] && products[0][1].short_name}"
                </span>
              </h2>
              <div className="blockProduct">
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      id={'product-' + products[0][1].id}
                      className="prRadio"
                      type="radio"
                      name="product-annett"
                      data-price={products[0][1].price}
                      data-product={products[0][1].name}
                      value={products[0][1].id}
                      data-prid={products[0][1].id}
                      data-cprice={products[0][1].clear_price}
                      checked={selectedProductId === products[0][1].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[0][1].id}
                      className="prLabel"
                    >
                      {products[0] && products[0][1].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[0] && products[0][1].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[2][0].id}
                      type="radio"
                      name="product-annett"
                      data-price={products[2][0].price}
                      data-product={products[2][0].name}
                      value={products[2][0].id}
                      data-prid={products[2][0].id}
                      data-cprice={products[2][0].clear_price}
                      checked={selectedProductId === products[2][0].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[2][0].id}
                      className="prLabel"
                    >
                      {products[2] && products[2][0].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[2] && products[2][0].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[3][1].id}
                      type="radio"
                      name="product-annett"
                      value={products[3][1].id}
                      data-price={products[3][1].price}
                      data-product={products[3][0].name}
                      data-prid={products[3][1].id}
                      data-cprice={products[3][1].clear_price}
                      checked={selectedProductId === products[3][1].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[3][1].id}
                      className="prLabel"
                    >
                      {products[3] && products[3][1].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[3] && products[3][1].price}</strong>
                  </span>
                </div>
              </div>
            </div>

            {/* «ВСЕ ВКЛЮЧЕНО» */}
            <div className="ProductWrapper ProductWrapper-hide">
              <h2 className="allProductsFormTitle">
                тариф{' '}
                <span id="purple-text">
                  "{products[0] && products[0][0].short_name}"
                </span>
              </h2>
              <div className="blockProduct">
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      id={'product-' + products[0][0].id}
                      className="prRadio"
                      type="radio"
                      name="product-all"
                      data-price={products[0][0].price}
                      data-product={products[0][0].name}
                      value={products[0][0].id}
                      data-prid={products[0][0].id}
                      data-cprice={products[0][0].clear_price}
                      checked={selectedProductId === products[0][0].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[0][0].id}
                      className="prLabel"
                    >
                      {products[0] && products[0][0].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[0] && products[0][0].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[1][1].id}
                      type="radio"
                      name="product-all"
                      data-price={products[1][1].price}
                      data-product={products[1][1].name}
                      value={products[1][1].id}
                      data-prid={products[1][1].id}
                      data-cprice={products[1][1].clear_price}
                      checked={selectedProductId === products[1][1].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[1][1].id}
                      className="prLabel"
                    >
                      {products[1] && products[1][1].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[1] && products[1][1].price}</strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'product-' + products[3][0].id}
                      type="radio"
                      name="product-all"
                      value={products[3][0].id}
                      data-price={products[3][0].price}
                      data-product={products[3][0].name}
                      data-prid={products[3][0].id}
                      data-cprice={products[3][0].clear_price}
                      checked={selectedProductId === products[3][0].id}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'product-' + products[3][0].id}
                      className="prLabel"
                    >
                      {products[3] && products[3][0].period_name}
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена: <strong>{products[3] && products[3][0].price}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Promocode disabled={isDisabled} />
          <div>
            <Button
              type="button"
              value="Перейти к оплате"
              fields={{ email }}
              setIsWaitingAnswer={setIsWaitingAnswer}
              method="buy"
              validate={setErrors}
              existsErrors={setExistsError}
              setAnswerText={setAnswerText}
              disabled={isDisabled}
            />
          </div>
        </>
      )}
    </div>
  );
};
