import React, { useState, useEffect } from "react";
import Loading from "../../Loader/Loader";
import BC365Service from "../../../services/beautyclub365";
import { useUserContext } from "../../../context/UserContext";
import "./WaitingTrialAnswer.css";

export default WaitingTrialAnswer = () => {

	const [errors, setErrors] = useState({});
	const { be } = errors;
	const { setBasket, products, defaultPrice, setDefaultPrice, userProfile } = useUserContext();

	const db = new BC365Service();

	// Step 1. Sales funnel (open payment popup)
	useEffect(() => {
		const { fio, email } = userProfile;
		db.openPayment({
			first_name: fio,
			email,
		}).then((answer) => {
			//console.log(answer);
		});
	}, [

	]);

	return (
		<div className="allProductsWrapper">
			<div className="loaderWrapper">
				<Loading width={100} />
			</div>
			<h3 className="formTitleTR">Подождите пару секунд...подключаем</h3>
		</div>
	);
}
