import React, { useState } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import Forgot from "../Forgot/Forgot";
import { usePopupContext } from "../../../context/PopupContext";
import "./Auth.css";

export default Auth = (props) => {

	const {
		setIsWaitingAnswer,
		errors,
		setErrors,
		existsError, 
		setExistsError,
	} = props;

	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const { be } = errors;

	const {setContent } = usePopupContext();

	const showForgotPassword = () => {
		setContent(<Forgot />);
	};
	
	return (
		<div className="auth">
			<Input
				labelText="E-mail или телефон"
				type="text"
				placeholder="miss1265@gmail.com"
				required={true}
				setValue={setLogin}
				errorText={errors.login}
				width="400px"
				existsError={existsError}
				setExistsError={setExistsError}
			/>
			<Input
				labelText="Пароль"
				type="password"
				placeholder="Введите пароль"
				required={true}
				setValue={setPassword}
				errorText={errors.password}
				width="400px"
				existsError={existsError}
				setExistsError={setExistsError}
			/>
			{typeof be !== 'undefined' && <span className="beckEndError">{be}</span>}
			<span className="forgotPasswort" onClick={showForgotPassword}>Забыли пароль?</span>
			<Button 
				type="button" 
				value="Войти" 
				fields={{login, password}} 
				setIsWaitingAnswer={setIsWaitingAnswer}
				method="login"
				validate={setErrors}
				existsErrors={setExistsError}
			/>
		</div>
	);
}
