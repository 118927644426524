import React, { useEffect, useState } from 'react';
import './payment.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import paymentTopIcon from '../../img/payment-icons/payment-top-icon.svg';
import paymentSupport from '../../img/payment-icons/payment-order-block-support.svg';
import paymentSupport1 from '../../img/payment-icons/payment-support-icon.svg';
import paymentSupport2 from '../../img/payment-icons/payment-support-icon-1.svg';
import phone from "../../img/footerIcons/phone.png";
import mail from "../../img/footerIcons/mail.png";
import notFound from "../../img/404.gif";
import AuthOrRegister from '../../components/Forms/AuthOrRegister/AuthOrRegister';
import Buy from '../../components/Forms/Buy/Buy';
import { useUserContext } from "../../context/UserContext";
import BC365Service from "../../services/beautyclub365";
import Loading from "../../components/Loader/Loader";

function Payment() {

  const db = new BC365Service();
  const [productStatus, setProductStatus] = useState({
    search: false,
    found: false,
  });
  const {
    userProfile,
    setUserProfile,
    userStatus,
    setUserStatus,
    setBasket,
    setIsCourse,
    setTypeOfProduct,
    basketData,
  } = useUserContext();
  // useState

  useEffect(() => {
    // is Online?
    db.isOnline().then((answer) => {
      let token = answer["is_online"] ? answer["session_id"] : "";
      setUserProfile({
        fio: answer["user_data"]["name"],
        email: answer["user_data"]["email"],
        avatar: answer["user_data"]["photo"],
        login: answer["user_data"]["login"],
        token: token,
        online: answer["is_online"],
        user_id: answer["user_data"]["user_id"],
        phone: "",
        docs_prefix: answer["docs_prefix"],
      });
      if (answer["is_online"]) {
        setUserStatus(2);
      } else {
        setUserStatus(1);
      }
    });
  }, [
    setUserProfile,
    setUserStatus,
  ]);

  useEffect(() => {
    const productId = new URLSearchParams(window.location.search).get('product_id') ?? 0;
    if (productId > 0) {
      db.getProductsByListOfIds(productId).then((answer) => {
        if (answer['products'].length > 0) {
          const product = answer['products'][0];
          setBasket(
            {
              price: product['price'],
              name: product['name'],
              price_clear: product['clear_price'],
              product_id: product['id'],
              payment_desc: product['payment_desc'],
            }
          );
          setIsCourse(true);
          setTypeOfProduct(2);
          setProductStatus({
            search: true,
            found: true,
          });
        } else {
          setProductStatus({
            search: true,
            found: false,
          });
        }
      });
    } else {
      setProductStatus({
        search: true,
        found: false,
      });
    }
  }, [
    setBasket,
    setIsCourse,
    setTypeOfProduct,
    setProductStatus,
  ]);

  return (
    <>
      <div className="payment-header">
        <div className="payment-header-container">
          <Link className="header-logo" to="/"/>
          <p className='payment-header-title'>страница оплаты</p>
        </div>
      </div>
      <div className='payment-container'>
        <div className='payment-top-block'>
          <img src={paymentTopIcon} alt="" className='payment-top-block-icon' />
          <div className='payment-top-block-content'>
            <h2 className='payment-top-block-h2'>Вперед за достижениями!</h2>
            <span className='payment-top-block-p'>У вас остался всего один шаг до лучшей версии себя! Укрепляйте свое здоровье, возвращайте молодость, хорошейте и напитывайтесь энергией и бодростью.</span>
          </div>
        </div>
        <h1 className='payment-container-h1'>Состав заказа:</h1>
        {basketData['product_id'] > 0 && (
          <div className='payment-order-block'>
            <div className='payment-order-block-first'>
              <h2 className='payment-order-block-first-h2'>Марафон <div style={{color:'#FF059B'}}>«{basketData['name']}»</div></h2>
              <span className='payment-order-block-first-p'>{basketData['payment_desc']}</span>
              <div>
                <div className='payment-order-block-first-price-title'>Стоимость:</div>
                <div className='payment-order-block-first-price'>{basketData['price_clear']} р.</div>
              </div>
              <div className='payment-order-block-support'>
                <span className='payment-order-block-support-text'>Сразу после оплаты вы получите доступ к марафону в вашем личном кабинете.</span>
                <span className='payment-order-block-support-bold-text'>Служба поддержки:</span>
                <div className='payment-order-block-support-phone'>
                  <img src={paymentSupport} alt="" />
                  <a className='payment-order-block-support-phone-p' href="tel:+78005117287">8 (800) 511-72-87</a>
                </div>
              </div>
            </div>
            <div className='payment-order-block-second'>
              {userProfile['user_id'] === 0 && userStatus === 1 && (
                <>
                  <h1 className='payment-order-block-second-h1'>Пожалуйста авторизуйтесь или зарегистрируйтесь 
                  <span style={{color:'#FF059B'}}> для оплаты заказа.</span></h1>
                  <AuthOrRegister />
                </>
                )}
              {userProfile['user_id'] > 0 && userStatus === 2 && <Buy usePromocode={true} />}
              {userProfile['user_id'] === 0 && userStatus === 0 && <Loading width="100" />}
            </div>
          </div>
        )}
        {basketData['product_id'] === 0 && (
          <div className='payment-order-block'>
            {productStatus['search'] && !productStatus['found'] && (
              <div className="notFoundBlock">
                <img className='payment-support-block-img-2' src={notFound} alt="404" />
                <p>Продукт не найден</p>
              </div>
            )}
            {!productStatus['search'] && <Loading width="100" />}
          </div>
        )}
        <div className='payment-support-block'>
          <img className='payment-support-block-img-1' src={paymentSupport1} alt="" />
          <img className='payment-support-block-img-2' src={paymentSupport2} alt="" />
          <h2 className='payment-support-block-h2'>Служба поддержки:</h2>
          <h4 className='payment-support-block-h4'>Если у вас возникли вопросы или сложности с оплатой, пожалуйста, свяжитесь с нашей службой поддержки удобным для вас способом:</h4>
          <div>
            <div className='payment-support-block-phone'>
              <img className='payment-support-block-phone-img' src={mail} alt="" />
              <a className='payment-support-block-phone-p' href="mailto:support@beautyclub365.ru">support@beautyclub365.ru</a>
            </div>
            <div className='payment-support-block-email'>
              <img className='payment-support-block-email-img' src={phone} alt="" />
              <a className='payment-support-block-email-p' href="tel:+78005117287">8 (800) 511-72-87</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment;
