import React from "react";
import Loader from "../../components/Loader/Loader";
import logo from "../../img/optimized/commoin/logo_white.webp";
import "./loading.css";

const Loading = () => {
	return (
		<div className="loadingWrapper">
			<div className="loading">
				<div>
					<img className="logo-icon-loading" src={logo} alt="logo full" />
				</div>
				<Loader width="80" />	
			</div>
		</div>
	);	  
}

export default Loading;
