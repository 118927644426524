import React, { useEffect, useState, useCallback } from "react";
import { PROXY_URL } from "../../shared/constants";
import "./VideoReview.css";
import BC365Service from "../../services/beautyclub365";

function VideoReview() {
  const db = new BC365Service();

  const [reviews, setReviews] = useState([]);
  const [visible, setVisible] = useState(3);
  const [showIframes, setShowIframes] = useState({});
  const loadMoreVideoReviews = () => {
    setVisible((prevValue) => prevValue + 3)
  }

  useEffect(() => {
    //get video reviews
    db.getVideoReviews().then((data) => {
      setReviews(data);
      let videos = {};
      data.forEach((_, index) => {
        videos[index] = 0;
      });
      setShowIframes(videos);
    })
  }, [
    setShowIframes,
  ]);

  const loadIframe = useCallback((index) => {
    showIframes[index] = 1;
    setShowIframes({
      ...showIframes,
      index: 1,
    });
  }, []);

  return (
    <div className="video-reviews_container" id="video-reviews">
      <div className="text-11-1 montserrat-extra-bold-stratos-48px">
        Посмотрите, что говорят участницы клуба
      </div>
      <div className="video-container">
        {reviews.slice(0, visible).map((item, index) => {
          return (
            <div className="video-reviews-box" key={index}>
              {!showIframes[index] && (
                <img 
                  className="image_video_block" 
                  src={PROXY_URL + '/upload' + item.image} 
                  alt={item.reviewer_name}
                  onClick={() => loadIframe(index)}
                />
              )}
              {showIframes[index] === 1 && (
                <iframe
                  className="video-reviews_block"
                  src={item.url + '?autoplay=1'}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              )}
              <div style={{padding: '0 20px'}}>
                <p className="video-reviews-description">
                  "{item.description}"
                </p>
                <p className="video-reviews-name">{item.reviewer_name}</p>
              </div>
            </div>
          )
        })}
        {reviews.length > visible && 
          <button className="load-more-reviews" onClick={loadMoreVideoReviews}>Показать больше отзывов</button>
        }
      </div>
    </div>
  )
}

export default VideoReview;
