import { createContext, useContext, useMemo, useState, useCallback, useEffect } from "react";
import {getUserOnlineData} from "../shared/utils";

const initialState = {
  userProfile: {
    fio: '',
	email: '',
	phone: '',
	avatar: '',
	token: '',
	login: '',
	online: false,
	user_id: 0,
	docs_prefix: 'rf',
  },
  userPublicData: {
	avatar: '',
	name: '',
	typeAvatar: 'text',
  },
  basketData: {
	price_clear: 0,
	price: 0,
	name: '',
	product_id: 0,
	payment_desc: '',
  },
  orderData: {
	accountId: '', // from user context login
  	paysystem: 'CLOUDPAYMENTS',
	amount: 0, // clear price (depend or RU or no)
	currency: '', // from state
	data: {
		order_id: 0,
		order_type: 'paid_subscribe_new',
	},
	description: '',
	invoiceId: 0,
	publicId: '', // from const
	publiskincId: 'mini', // good mode
  },
  isCertificate: false,
  setIsCertificate: () => {},
  setUserProfile: () => {},
  setBasket: () => {},
  setOrder: () => {},
  products: [],
  setProducts: () => {},
  defaultPrice: 0,
  setDefaultPrice: () => {},
  // User can see test drive in 2 cases: 1) not authorized 2) authorized and can use trial
  showTestDrive: true,
  setShowTestDrive: () => {},
  userCanUseTrial: false,
  setUserCanUseTrial: () => {},
  preUserLogin: '',
  setPreUserLogin: () => {},
  justBuyCertificate: false,
  setJustBuyCertificate: () => {},
  isCourse: false,
  setIsCourse: () => {},
  menuRef: '',
  setMenuRef: () => {},
  userStatus: 0,	// 0 - unknown (before checking), 1 - not authorized (after checking), 2 - authorized (after checking) 
  setUserStatus: () => {},
  typeOfProduct: 2, // 1 - course, 2 - marathon
  setTypeOfProduct: () => {},
  settings: {
	  show_button_gift_certificate: 'N',
  },
  setSettings: () => {},
};

const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {

	const [userProfile, setUserProfile] = useState(initialState.userProfile);
	const [ userPublicData, setUserPublicData ] = useState(initialState.userPublicData);
	const [basketData, setBasket] = useState(initialState.basketData);
	const [orderData, setOrder] = useState(initialState.orderData);
	const [isCertificate, setIsCertificate] = useState(initialState.isCertificate);
	const [products, setProducts] = useState(initialState.products);
	const [defaultPrice, setDefaultPrice] = useState(initialState.defaultPrice);
	const [showTestDrive, setShowTestDrive] = useState(initialState.showTestDrive);
	const [userCanUseTrial, setUserCanUseTrial] = useState(initialState.userCanUseTrial);
	const [preUserLogin, setPreUserLogin] = useState(initialState.preUserLogin);
	const [justBuyCertificate, setJustBuyCertificate] = useState(initialState.justBuyCertificate);
	const [isCourse, setIsCourse] = useState(initialState.isCourse);
	const [menuRef, setMenuRef] = useState(initialState.menuRef);
	const [userStatus, setUserStatus] = useState(initialState.userStatus);
	const [typeOfProduct, setTypeOfProduct] = useState(initialState.typeOfProduct);
	const [settings, setSettings] = useState(initialState.settings);

	useEffect(() => {
		const profile = getUserOnlineData(userProfile);
		setUserPublicData(profile);
	}, [
		setUserProfile,
		userProfile,
	]);

  	const value = useMemo(() => {
		return {
			setUserProfile,
			userProfile,
			userPublicData,
			basketData,
			setBasket,
			orderData,
			setOrder,
			isCertificate,
			setIsCertificate,
			products,
			setProducts,
			defaultPrice,
			setDefaultPrice,
			showTestDrive,
			setShowTestDrive,
			userCanUseTrial,
			setUserCanUseTrial,
			preUserLogin,
			setPreUserLogin,
			justBuyCertificate,
			setJustBuyCertificate,
			isCourse,
			setIsCourse,
			menuRef,
			setMenuRef,
			userStatus,
			setUserStatus,
			typeOfProduct,
			setTypeOfProduct,
			setSettings,
			settings
		};
  }, [
	setUserProfile,
	userProfile,
	setSettings,
	settings,
	userPublicData,
	basketData,
	setBasket,
	orderData,
	setOrder,
	isCertificate,
	setIsCertificate,
	products,
	setProducts,
	defaultPrice,
	setDefaultPrice,
	showTestDrive,
	setShowTestDrive,
	userCanUseTrial,
	setUserCanUseTrial,
	preUserLogin,
	setPreUserLogin,
	justBuyCertificate,
	setJustBuyCertificate,
	isCourse,
	setIsCourse,
	menuRef,
	setMenuRef,
	userStatus,
	setUserStatus,
	typeOfProduct,
	setTypeOfProduct,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  return useContext(UserContext);
};
