import React from "react";
import { getImagesPaths } from "../../shared/utils";
import { MIN_WIDTH_TO_GET_MOBILE_IMAGE } from "../../shared/constants";
import "./IndividualProgrammBlock.css";
function IndividualProgrammBlock(props) {
  const { 
    individualProgrammImages,
    individualProgrammImagesOpt,
   } = props;

   // prepare images
  let contentImages = {};
  if (individualProgrammImagesOpt.length > 0) {
    contentImages = getImagesPaths(individualProgrammImagesOpt);
  } else {
    contentImages = getImagesPaths(individualProgrammImages);
  }
  return (
    <div className="individual-programm_block">
      <div className="overlap-group10">
        <img className="image-20" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['main'] : contentImages['main_mobile']} alt="image 20" />
        <div className="text-72 montserrat-extra-bold-stratos-48px">
          Составьте индивидуальную программу и работайте над своим запросом
          комплексно
        </div>
        <img className="x1-1" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im1'] : contentImages['im1_mobile']} alt="1" />
        <img className="x2" src={window.innerWidth > MIN_WIDTH_TO_GET_MOBILE_IMAGE ? contentImages['im2'] : contentImages['im2_mobile']} alt="2" />
      </div>
    </div>
  );
}

export default IndividualProgrammBlock;
