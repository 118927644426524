import React, { useState } from "react";
import Input from "../Elements/Input/Input";
import Button from "../Elements/Button/Button";
import { useUserContext } from "../../../context/UserContext";
import { PROXY_URL } from "../../../shared/constants";
import Loading from "../../Loader/Loader";
import "./JustRegister.css";

export default JustRegister = ({ selectedTariff }) => {

	const { 
		preUserLogin,
	} = useUserContext();
	const [name, setName] = useState('');
	const [secondName, setSecondName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [phoneFieldConfirmed, setPhoneFieldConfirmed] = useState(false);
	const [activeButton, setActiveButton] = useState(true);
	const [isWaitingAnswer, setIsWaitingAnswer] = useState(false);
	const [errors, setErrors] = useState({
		name: '',
		secondName: '',
		email: '',
		phone: '',
		password: '',
		repeatPassword: '',
	});
	const [existsError, setExistsError] = useState(false);
	const { be } = errors;
	return (
		<div className="register">
			{
				isWaitingAnswer &&
				<div className="loaderWrapper">
					<Loading width={100} />
				</div>
			}
			{ !isWaitingAnswer && 
				<>
					<h2 className="formTitle">Регистрация</h2>
					<div className="registerRow">
						<Input
							labelText="Фамилия"
							type="text"
							placeholder="Ваша фамилия"
							width="260px"
							required={true}
							setValue={setSecondName}
							errorText={errors.secondName}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
						<Input
							labelText="Имя"
							type="text"
							placeholder="Ваше имя"
							width="260px"
							required={true}
							setValue={setName}
							errorText={errors.name}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
					</div>
					<div className="registerRow">
						<Input
							labelText="E-mail"
							type="text"
							placeholder="miss1265@gmail.com"
							required={true}
							width="260px"
							setValue={setEmail}
							inputValue={preUserLogin}
							errorText={errors.email}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
						<Input
							labelText="Телефон"
							type="text"
							placeholder="7(XXX)XXX-XX-XX"
							required={true}
							width="260px"
							setValue={setPhone}
							errorText={errors.phone}
							phoneValidation={true}
							activateButton={setActiveButton}
							setPhoneFieldConfirmed={setPhoneFieldConfirmed}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
					</div>
					<div className="registerRow">
						<Input
							labelText="Пароль"
							type="password"
							placeholder="Введите пароль"
							required={true}
							width="260px"
							setValue={setPassword}
							errorText={errors.password}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
						<Input
							labelText="Повторите пароль"
							type="password"
							placeholder="Введите пароль"
							required={true}
							width="260px"
							setValue={setRepeatPassword}
							errorText={errors.repeatPassword}
							existsError={existsError}
							setExistsError={setExistsError}
						/>
					</div>
					{typeof be !== 'undefined' && <span className="beckEndError">{be}</span>}
					<Button 
						type="button" 
						value="Зарегистрироваться"
						fields={{name, secondName, email, phone, password, repeatPassword}} 
						selectedTariff={selectedTariff}
						phoneFieldConfirmed={phoneFieldConfirmed}
						method="register-and-buy"
						validate={setErrors}
						disabled={!activeButton}
						existsErrors={setExistsError}
						setIsWaitingAnswer={setIsWaitingAnswer}
					/>
					<span className="registerNotice">
						Нажимая "Зарегистрироваться" я даю согласие на обработку 
						моих персональных данных и принимаю условия 
						<a target="_blank" href={PROXY_URL + "/terms-conditions/"}>политики конфиденциальности</a>
					</span>
				</>
			}
		</div>
	);
}

