import React from 'react';
import './TariffDetailPopUp.css';

function AnnettTariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «ТОЛЬКО АННЕТТ»</h1>
      <p className="tariffDetail-description">
        Начните утро с Аннетт! В прямом эфире или в записи вы вместе выполняете
        упражнения и массажи, которые подтянут овал лица, уберут отеки, заметно
        уменьшат морщины. <br /> <br /> Массажные техники, в том числе, баночный
        массаж (вакуум) и буккальный массаж, которые вы освоите с Аннетт,
        сработают, как мощнейший антиоксидант! <br /> <br /> Результатам вы
        порадуетесь уже после 3-5 эфира, и в дальнейшем они будут улучшаться -
        вы увидите это сами и услышите комплименты от других! <br /> <br />{' '}
        Занимаясь с Аннетт каждое утро, вы обретете полезную привычку
        поддерживать здоровье и молодость лица и тела. <br /> <br /> Кроме того,
        вы станете участниками уникальных «чуфырных» эфиров и медитаций Аннетт.
        Бытовая магия поможет вам улучшить климат в семье и благосостояние дома,
        а работа с подсознанием откроет перед вами новые скрытые возможности.{' '}
        <br /> <br />
      </p>
    </div>
  );
}

export default AnnettTariffDetailPopup;
