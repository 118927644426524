import React from "react";
import "./MainMenu.css";

export default function MainMenu(props) {

  const {
    goToFromMenu,
    WhatYouHaveBlockRef,
    WhatYouGetBlockRef,
    SecretOfSuccessRef,
    ChangeLifeRef,
    ResultsRef,
    PaymentRef,
    ExpertsRef,
    ScheduleRef,
  } = props;

  return (
      <div className="navbar_container">
        <a className="nav_link" href="#for_whow" onClick={e => goToFromMenu(e, WhatYouHaveBlockRef, 'WhatYouHaveBlockRef')}>Для кого</a>
        <a className="nav_link" href="#why_need_bc" onClick={e => goToFromMenu(e, WhatYouGetBlockRef, 'WhatYouGetBlockRef')}>Зачем нужен клуб</a>
        <a className="nav_link" href="#success" onClick={e => goToFromMenu(e, SecretOfSuccessRef, 'SecretOfSuccessRef')}>Направления</a>
        <a className="nav_link" href="#life" onClick={e => goToFromMenu(e, ChangeLifeRef, 'ChangeLifeRef')}>Что дает клуб</a>
        <a className="nav_link" href="#results" onClick={e => goToFromMenu(e, ResultsRef, 'ResultsRef')}>Результаты участниц</a>
        <a className="nav_link" href="#payments" onClick={e => goToFromMenu(e, PaymentRef, 'PaymentRef')}>Тарифы</a>
        <a className="nav_link" href="#experts" onClick={e => goToFromMenu(e, ExpertsRef, 'ExpertsRef')}>Спикеры</a>
        <a className="nav_link" href="#schedule" onClick={e => {
          goToFromMenu(e, ScheduleRef, 'ScheduleRef');
          dataLayer.push({'event': 'schedule'});
        }}>Расписание</a>
      </div>
  );
}

