import React, { useCallback, useEffect, useState } from 'react';
import Loading from '../../Loader/Loader';
import '../AllProducts/AllProducts.css';
import BC365Service from '../../../services/beautyclub365';
import { useUserContext } from '../../../context/UserContext';
import { Container, ListGroup, Col, Tab, Row } from 'react-bootstrap';

export default function Certificates() {
  window.location.href.split('#')[0];

  const db = new BC365Service();
  const [email, setEmail] = useState('');
  const [isWaitingAnswer, setIsWaitingAnswer] = useState(true);
  const [errors, setErrors] = useState({});
  const [existsError, setExistsError] = useState(false);
  const [answerText, setAnswerText] = useState('');
  const [certificates, setCertificates] = useState([]);
  const [price, setPrice] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const { be } = errors;
  const { setBasket } = useUserContext();

  useEffect(() => {
    db.getCertificates().then((answer) => {
      if (answer['products'].length > 0) {
        setPrice(0);
        const productPairs = answer['products'].reduce(
          (result, value, index, array) => {
            if (index % 2 === 0) {
              result.push(array.slice(index, index + 2));
            }
            return result;
          },
          []
        );
        setCertificates(productPairs);
        setIsWaitingAnswer(false);
      }
    });
  }, [setCertificates]);

  const changePrice = useCallback(
    (e) => {
      setPrice(e.target.getAttribute('data-price'));
      setBasket({
        price: e.target.getAttribute('data-price'),
        name: e.target.getAttribute('data-product'),
        price_clear: e.target.getAttribute('data-cprice'),
        product_id: e.target.getAttribute('data-prid'),
        payment_desc: '',
      });
      setIsDisabled(false);
    },
    [setPrice, setBasket, price, setIsDisabled]
  );

  return (
    <div className="allProductsWrapper">
      {isWaitingAnswer && (
        <div className="loaderWrapper">
          <Loading width={100} />
        </div>
      )}
      {!isWaitingAnswer && (
        <>
          <div className="allProducts-container">
            <h2 className="allProductsTitle">Выберите тариф</h2>
            <h3 className="allProductsTextMobile">
              Пожалуйста, выберите необходимый тариф из выпадающего списка ниже.
            </h3>
            <div className="ProductWrapper ProductWrapper-mobile">
              <Container>
                <Tab.Container
                  id="list-group-tabs-example"
                  defaultActiveKey="#link1"
                >
                  <Row>
                    <Col className="frame">
                      <ListGroup variant="flush">
                        <ListGroup.Item action href="#link1">
                          <div className="blockProductSubRadio">
                            <input
                              id={
                                'cert-tariff' + certificates[0] &&
                                certificates[0][1].certificate_tariff
                              }
                              className="prRadio"
                              type="radio"
                              name="consultation"
                            />
                            <label
                              htmlFor={
                                'cert-tariff' + certificates[0] &&
                                certificates[0][1].certificate_tariff
                              }
                              className="prLabel"
                            >
                              <h2 className="allProductsFormTitle1">
                                тариф{' '}
                                <span id="pink-text">
                                  "
                                  {certificates[0] &&
                                    certificates[0][1].certificate_tariff}
                                  "
                                </span>
                              </h2>
                            </label>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item action href="#link2">
                          <div className="blockProductSubRadio">
                            <input
                              id={
                                'cert-tariff' +
                                  'cert-tariff' +
                                  certificates[0] &&
                                certificates[0][0].certificate_tariff
                              }
                              className="prRadio"
                              type="radio"
                              name="consultation"
                            />
                            <label
                              htmlFor={
                                'cert-tariff' +
                                  'cert-tariff' +
                                  certificates[0] &&
                                certificates[0][0].certificate_tariff
                              }
                              className="prLabel"
                            >
                              <h2 className="allProductsFormTitle1">
                                тариф{' '}
                                <span id="purple-text">
                                  "
                                  {certificates[0] &&
                                    certificates[0][0].certificate_tariff}
                                  "
                                </span>
                              </h2>
                            </label>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="#link1">
                          <div className="blockProduct">
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  id={
                                    'consultation-mob-' + certificates[0][1].id
                                  }
                                  className="prRadio"
                                  type="radio"
                                  name="consultation"
                                  data-price={certificates[0][1].price}
                                  data-product={certificates[0][1].name}
                                  value={certificates[0][1].id}
                                  data-prid={certificates[0][1].id}
                                  data-cprice={certificates[0][1].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[0][1].id
                                  }
                                  className="prLabel"
                                >
                                  1 месяц
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[0] && certificates[0][1].price}
                                </strong>
                              </span>
                            </div>
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  className="prRadio"
                                  id={
                                    'consultation-mob-' + certificates[1][1].id
                                  }
                                  type="radio"
                                  name="consultation"
                                  data-price={certificates[1][1].price}
                                  data-product={certificates[1][1].name}
                                  value={certificates[1][1].id}
                                  data-prid={certificates[1][1].id}
                                  data-cprice={certificates[1][1].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[1][1].id
                                  }
                                  className="prLabel"
                                >
                                  6 месяцев
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[1] && certificates[1][1].price}
                                </strong>
                              </span>
                            </div>
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  className="prRadio"
                                  id={
                                    'consultation-mob-' + certificates[2][1].id
                                  }
                                  type="radio"
                                  name="consultation"
                                  value={certificates[2][1].id}
                                  data-price={certificates[2][1].price}
                                  data-product={certificates[2][1].name}
                                  data-prid={certificates[2][1].id}
                                  data-cprice={certificates[2][1].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[2][1].id
                                  }
                                  className="prLabel"
                                >
                                  12 месяцев
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[2] && certificates[2][1].price}
                                </strong>
                              </span>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="#link2">
                          <div className="blockProduct">
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  id={
                                    'consultation-mob-' + certificates[0][0].id
                                  }
                                  className="prRadio"
                                  type="radio"
                                  name="consultation"
                                  data-price={certificates[0][0].price}
                                  data-product={certificates[0][0].name}
                                  value={certificates[0][0].id}
                                  data-prid={certificates[0][0].id}
                                  data-cprice={certificates[0][0].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[0][0].id
                                  }
                                  className="prLabel"
                                >
                                  1 месяц
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[0] && certificates[0][0].price}
                                </strong>
                              </span>
                            </div>
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  className="prRadio"
                                  id={
                                    'consultation-mob-' + certificates[1][0].id
                                  }
                                  type="radio"
                                  name="consultation"
                                  data-price={certificates[1][0].price}
                                  data-product={certificates[1][0].name}
                                  value={certificates[1][0].id}
                                  data-prid={certificates[1][0].id}
                                  data-cprice={certificates[1][0].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[1][0].id
                                  }
                                  className="prLabel"
                                >
                                  6 месяцев
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[1] && certificates[1][0].price}
                                </strong>
                              </span>
                            </div>
                            <div className="blockProductSub">
                              <div className="blockProductSubRadio">
                                <input
                                  className="prRadio"
                                  id={
                                    'consultation-mob-' + certificates[2][0].id
                                  }
                                  type="radio"
                                  name="consultation"
                                  value={certificates[2][0].id}
                                  data-price={certificates[2][0].price}
                                  data-product={certificates[2][0].name}
                                  data-prid={certificates[2][0].id}
                                  data-cprice={certificates[2][0].clear_price}
                                  onChange={changePrice}
                                />
                                <label
                                  htmlFor={
                                    'consultation-mob-' + certificates[2][0].id
                                  }
                                  className="prLabel"
                                >
                                  12 месяцев
                                </label>
                              </div>
                              <span className="prPrice">
                                Цена:{' '}
                                <strong>
                                  {certificates[2] && certificates[2][0].price}
                                </strong>
                              </span>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Container>
            </div>
            {/* "ТОЛЬКО АННЕТТ" */}
            <div className="ProductWrapper ProductWrapper-hide">
              <h2 className="allProductsFormTitle">
                Подписка на тариф{' '}
                <span id="pink-text">
                  "{certificates[0] && certificates[0][1].certificate_tariff}"
                </span>
              </h2>
              <div className="blockProduct">
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      id={'consultation-' + certificates[0][1].id}
                      className="prRadio"
                      type="radio"
                      name="consultation"
                      data-price={certificates[0][1].price}
                      data-product={certificates[0][1].name}
                      value={certificates[0][1].id}
                      data-prid={certificates[0][1].id}
                      data-cprice={certificates[0][1].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[0][1].id}
                      className="prLabel"
                    >
                      1 месяц
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[0] && certificates[0][1].price}
                    </strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'consultation-' + certificates[1][1].id}
                      type="radio"
                      name="consultation"
                      data-price={certificates[1][1].price}
                      data-product={certificates[1][1].name}
                      value={certificates[1][1].id}
                      data-prid={certificates[1][1].id}
                      data-cprice={certificates[1][1].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[1][1].id}
                      className="prLabel"
                    >
                      6 месяцев
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[1] && certificates[1][1].price}
                    </strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'consultation-' + certificates[2][1].id}
                      type="radio"
                      name="consultation"
                      value={certificates[2][1].id}
                      data-price={certificates[2][1].price}
                      data-product={certificates[2][1].name}
                      data-prid={certificates[2][1].id}
                      data-cprice={certificates[2][1].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[2][1].id}
                      className="prLabel"
                    >
                      12 месяцев
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[2] && certificates[2][1].price}
                    </strong>
                  </span>
                </div>
              </div>
            </div>

            {/* «ВСЕ ВКЛЮЧЕНО» */}
            <div className="ProductWrapper ProductWrapper-hide">
              <h2 className="allProductsFormTitle">
                Подписка на тариф{' '}
                <span id="purple-text">
                  "{certificates[0] && certificates[0][0].certificate_tariff}"
                </span>
              </h2>
              <div className="blockProduct">
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      id={'consultation-' + certificates[0][0].id}
                      className="prRadio"
                      type="radio"
                      name="consultation"
                      data-price={certificates[0][0].price}
                      data-product={certificates[0][0].name}
                      value={certificates[0][0].id}
                      data-prid={certificates[0][0].id}
                      data-cprice={certificates[0][0].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[0][0].id}
                      className="prLabel"
                    >
                      1 месяц
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[0] && certificates[0][0].price}
                    </strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'consultation-' + certificates[1][0].id}
                      type="radio"
                      name="consultation"
                      data-price={certificates[1][0].price}
                      data-product={certificates[1][0].name}
                      value={certificates[1][0].id}
                      data-prid={certificates[1][0].id}
                      data-cprice={certificates[1][0].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[1][0].id}
                      className="prLabel"
                    >
                      6 месяцев
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[1] && certificates[1][0].price}
                    </strong>
                  </span>
                </div>
                <div className="blockProductSub">
                  <div className="blockProductSubRadio">
                    <input
                      className="prRadio"
                      id={'consultation-' + certificates[2][0].id}
                      type="radio"
                      name="consultation"
                      value={certificates[2][0].id}
                      data-price={certificates[2][0].price}
                      data-product={certificates[2][0].name}
                      data-prid={certificates[2][0].id}
                      data-cprice={certificates[2][0].clear_price}
                      onChange={changePrice}
                    />
                    <label
                      htmlFor={'consultation-' + certificates[2][0].id}
                      className="prLabel"
                    >
                      12 месяцев
                    </label>
                  </div>
                  <span className="prPrice">
                    Цена:{' '}
                    <strong>
                      {certificates[2] && certificates[2][0].price}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Promocode disabled={isDisabled} />
          <div>
            <Button
              type="button"
              value="Перейти к оплате"
              fields={{ email }}
              setIsWaitingAnswer={setIsWaitingAnswer}
              method="buy"
              validate={setErrors}
              existsErrors={setExistsError}
              setAnswerText={setAnswerText}
              disabled={isDisabled}
            />
          </div>
        </>
      )}
    </div>
  );
}
