import React from "react";
import "./MotivationBlock.css";
import kamadaeva from "../../img/optimized/commoin/kamadaeva.webp";

function MotivationBlock() {
  return (
    <div className="motivation_block" id="motivation_block">
      <div className="beauty-club365-1 montserrat-extra-bold-stratos-48px">
        BeautyClub365 не станет очередным курсом, который вы прошли и забыли!
      </div>
      <div className="flex-row-4">
        <div className="flex-col-2">
          <img className="image-6" src={kamadaeva} alt="image 6" />
          <div className="frame-35">
            <div className="text-38 montserrat-extra-bold-stratos-18px">
              Татьяна Камадеева
            </div>
            <p className="text-39 inter-normal-stratos-14px">
              Дипломированный тренер-эксперт по дыхательным гимнастикам, профессиональный психолог, автор эффективных систем похудения и оздоровления организма.
            </p>
          </div>
        </div>
        <div className="frame-79-1">
          <div className="text-40 montserrat-extra-bold-hollywood-cerise-64px">
            «
          </div>
          <div className="text-41 caveat-normal-stratos-32px">
            Часто слышу от женщин жалобу: купила курс, прошла и забыла, новых
            привычек не сформировалось, и результаты сошли на нет. <br />
            Клуб — это постоянный контекст и поддерживающее сообщество. Вы
            находитесь в клубной среде постоянно, и она мотивирует вас меняться.
            Благодаря чему вы не просто получаете знания, вы вырабатываете
            привычки, которые останутся с вами на всю жизнь!
          </div>
          <div className="text-42 montserrat-extra-bold-hollywood-cerise-64px">
            »
          </div>
        </div>
      </div>
    </div>
  );
}

export default MotivationBlock;
