const validateField = (type, data) => {
	if (type === 'login') {
		if (data.length < 1) {
			return 'Данное поле не может быть пустым';
		}
	}
	if (type === 'password' || type === 'repeatPassword') {
		if (data.length < 6) {
			return 'Пароль не может быть менее 6 символов';
		}
	}
	if (type === 'name' || type === 'secondName') {
    if (!/^[а-яА-Яa-zA-ZёЁ-]{1,50}$/.test(data.replaceAll(/\s/g,''))) {
			return 'Данное поле должно содержать только русские/английские буквы, знак "-" и не превышать 50 символов';
		}
	}
	if (type === 'email') {
		if (!isValidEmail(data))
			return 'E-mail некорректно задан';
	}
	if (type === 'phone') {
		if (!/^\d+$/.test(data)) {
			return 'Номер телефона должен состоять только из цифр';
		}
	}

	return '';
};

export const validateRegister = (fields) => {
	const errors = {};
	for (const [key, value] of Object.entries(fields)) {
		errors[key] = validateField(key, value);
	}
	// check repeat password
	const countErrors = Object.values(errors).filter(val => val !== '');
	if (countErrors.length === 0 && fields['password'] !== fields['repeatPassword']) {
		errors['repeatPassword'] = 'Пароли не совпадают';
	}
	return errors;
};

export const validateAuth = (fields) => {
	const errors = {};
	for (const [key, value] of Object.entries(fields)) {
		errors[key] = validateField(key, value);
	}
	return errors;
};

export const isRussianNumber = (number) => {
	if (/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(number)) {
		return true;
	}
	return false;
};

export const isValidEmail = (email) => {
	const regex = new RegExp('[a-zA-Z0-9-]+@[a-z]+\.[a-z0-9]{2,5}');
	if (!regex.test(email))
		return false;

	return true;
}