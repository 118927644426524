import React, { useEffect, useState } from "react";
import { UserProvider } from "./context/UserContext";
import { PopupProvider } from "./context/PopupContext";
import { MarketProvider } from "./context/MarketContext";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Init from "./pages/main/init";
import Main from "./pages/main/main";
import Courses from "./pages/main/courses";
import Payment from "./pages/main/payment";
import 'bootstrap/dist/css/bootstrap.min.css';
import BC365Service from "./services/beautyclub365";


function App() {
  useEffect(() => {
    const db = new BC365Service();
    let res = db.fixAnalytic();
  }, []);

  const [mainRouter, setMainRouter] = useState(<Main {...bc365Data} />);

  return (
    <UserProvider>
      <PopupProvider>
        <MarketProvider>
          <Router>
            <Route path="/:path(|bc365)">
              {mainRouter}
            </Route>
            <Route path="/react">
              {mainRouter}
            </Route>
            <Route path="/react-test">
              {mainRouter}
            </Route>
            <Route path="/test-banner">
              {mainRouter}
            </Route>
            <Route path="/market">
              <Courses />
            </Route>
            <Route path="/payment">
              <Payment />
            </Route>
          </Router>
        </MarketProvider>
      </PopupProvider>
    </UserProvider>
  );
}

export default App;

const x1Data = {
  rectangle14: require('./img/optimized/commoin/rectangle-14-1@2x.webp'),
  butterfly: require('./img/optimized/commoin/butterfly-1@2x.webp'),
  rectangle15: require('./img/optimized/commoin/rectangle-15-1@2x.webp'),
};

const whatYouGetBlockData = {
  whatyouget_Block: require('./img/optimized/commoin/image-22-1@2x.webp'),
};

const communitySupportBlockData = {
  rectangle41: require('./img/optimized/commoin/rectangle-38-1@2x.webp'),
  rectangle42: require('./img/optimized/commoin/rectangle-42-1@2x.webp'),
};

const scheduleBlockData = {
  line3: require('./img/optimized/commoin/line-3-1@2x.webp'),
};

const beSpeakerBlockData = {
  rectangle49: require('./img/optimized/commoin/rectangle-49-1@2x.webp'),
};

const bc365Data = {
  x1Props: x1Data,
  whatYouGetBlockProps: whatYouGetBlockData,
  communitySupportBlockProps: communitySupportBlockData,
  scheduleBlockProps: scheduleBlockData,
  beSpeakerBlockProps: beSpeakerBlockData,
};

window.onerror = function(msg, url, line, col, exception) {
  let params = new URLSearchParams();
  params.set('msg', msg);
  params.set('url', url);
  params.set('line', line);
  params.set('col', col);

  if (typeof exception === 'object' && exception !== null)
    params.set('stack', exception.stack);

  fetch('/local/ajax/js_error.php', {
    method: 'POST',
    body: params
  });
}
