const data = {
	config: {
		autoplay: 0,
		time: 1,
		advertise_text: 'Реклама. ООО «М-БК». ИНН 9702055857',
	},
	banners: {
		desktop: [
			{
				show: true,
				image: require('/src/img/banner/1.png'),
				text: `
					<span 
						style='
						color:#ff059b; 
						font-family: "Montserrat",Helvetica;
						font-weight: 800; 
						font-size: 40px;'
					>ЗАМОРАЖИВАЕМ<br> ЦЕНЫ<br></span>
					<span
						style='
						color:#fff; 
						font-family: "Montserrat",Helvetica;
						font-weight: 800; 
						font-size: 40px;'
					>ДО -30% НА ВСЁ
					</span>
					<p></p>
					<span
						style='
						color:#fff; 
						font-family: "Montserrat",Helvetica;
						font-size: 18px;'
					>
						Знаем, чем вас согреть в такие холода - горячими<br> 
						скидками на все тарифы Клуба до 29.02 - самого<br> 
						редкого дня в году!!<br><br> 
					</span>
					<span
						style='
						color:#fff; 
						font-family: "Montserrat",Helvetica;
						font-size: 18px;'
					>
						<span style="color:#ff059b;">- Новичок?</span> 
						Для вас <span style="color:#ff059b;">-30%</span> и цены от 980 р/мес<br> 
		
						<span style="color:#ff059b;">- Клубничка?</span> 
						Забирай скидку <span style="color:#ff059b;">-20%</span> на любой тариф и срок
					</span>
				`,
				button: {
					color: '#ff059b',
					link: '/personal/subscription-feed/',
					text_button: 'Присоединиться',
					text_color: '#fff',
				},
			},
			{
				show: true,
				image: require('/src/img/banner/2.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/3.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/4.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/5.png'),
			},
		],
		mobile:[
			{
				show: true,
				image: require('/src/img/banner/1_mobile.png'),
				text: `
					<span 
						style='
						color:#ff059b; 
						font-family: "Montserrat",Helvetica;
						font-weight: 800; 
						font-size: 28px;'
					>ЗАМОРАЖИВАЕМ<br> ЦЕНЫ<br></span>
					<span
						style='
						color:#fff; 
						font-family: "Montserrat",Helvetica;
						font-weight: 800; 
						font-size: 30px;'
					>ДО -30% НА ВСЁ
					</span>
					<p></p>
					<span
						style='
						color:#fff; 
						font-family: "Montserrat",Helvetica;
						font-size: 18px;'
					>
						Знаем, чем вас согреть в такие холода - горячими<br> 
						скидками на все тарифы Клуба до 29.02 - самого<br> 
						редкого дня в году!!<br><br> 
					</span>
				`,
				button: {
					color: '#ff059b',
					link: '/personal/subscription-feed/',
					text_button: 'Присоединиться',
					text_color: '#fff',
				},
			},
			{
				show: true,
				image: require('/src/img/banner/2_mobile.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/3_mobile.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/4_mobile.png'),
			},
			{
				show: true,
				image: require('/src/img/banner/5_mobile.png'),
			},
		],
	},
	banners_preview: [
		{
			show: true,
			image: require('/src/img/banner/1_preview.png'),
			text: 'Заморозка цен клуба',
			top: 28,
			left: 15,
			color: '#fff',
			width: 110,
		},
		{
			show: true,
			image: require('/src/img/banner/2_preview.png'),
			text: 'Марафон «Животворящий +экспресс»',
			top: 18,
			left: 15,
			color: '#fff',
			width: 110,
		},
		{
			show: true,
			image: require('/src/img/banner/3_preview.png'),
			text: 'Конкурс репостов в вк',
			top: 30,
			left: 15,
			color: '#fff',
			width: 110,
		},
		{
			show: true,
			image: require('/src/img/banner/4_preview.png'),
			text: 'Конкурс в инст',
			top: 30,
			left: 15,
			color: '#fff',
			width: 110,
		},
		{
			show: true,
			image: require('/src/img/banner/5_preview.png'),
			text: 'Стодневка с Аннетт',
			top: 30,
			left: 15,
			color: '#fff',
			width: 95,
		},
	],
};

exports.data = data;
