import { PROXY_URL } from "./constants";

export const getPhoneMusk = (inputPhone) => {
	const phoneValue = inputPhone.current.value
		.replace(/\D/g, '')
		.match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/); 
		inputPhone.current.value = !phoneValue[2]
		? phoneValue[1]
		: `(${phoneValue[1]}) ${phoneValue[2]}${`${
			phoneValue[3] ? `-${phoneValue[3]}` : ''
		}`}${`${phoneValue[4] ? `-${phoneValue[4]}` : ''}`}`;
	return numbers = inputPhone.current.value.replace(/(\D)/g, '');
};

export const getImagesPaths = (images) => {
	let paths = {};
	if (images) {
		for (const key of images) {
			const { desc, image, mobile_image } = key;
			paths[`${desc}`]= `${PROXY_URL}/upload${image}`;
			paths[`${desc}_mobile`]= `${PROXY_URL}/upload${mobile_image}`;
		}
	};
	return paths;
};

export function debounce (fn, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, timeout);
	};
}

export function getUserOnlineData(userData) {

	let userProfile = {
		avatar: '',
		name: '',
		typeAvatar: 'text',
	};

	if (userData['online']) {
		// check exists photo or no
		if (userData['avatar'] !== '') {
			userProfile.avatar = userData['avatar'];
			userProfile.typeAvatar = 'image';
			userProfile.name = (userData['fio'] === '')? userData['login'] : userData['fio'];
		}

		// if not exists user photo we get initials from name
		if (userData['avatar'] === '' && userData['fio'] !== '') {
			userData['fio'] = userData['fio'].replace(/  +/g, ' ');
			let fioList = userData['fio'].split(' ');
			fioList = fioList.filter(n => n)
			let fioListFirstLetters = fioList.map((el) => el[0].toUpperCase());
			let initials = fioListFirstLetters.join('');
			userProfile.avatar = initials;
			userProfile.name = userData['fio'];
		}

		// if not exists user photo and empty name we get initials from login
		if (userData['avatar'] === '' && userData['fio'] === '' && userData['login'] !== '') {
			userProfile.avatar = userData['login'].substring(0, 2).toUpperCase();
			userProfile.name = userData['login'];
		}
	}

	return userProfile;
}

export function fromRubToKZT (value) {
	return Math.ceil(Number(value)*7.62);
}

export function prepareTextForWeek (number) {
	if ((number - 1) % 10 === 0) {
		return 'неделя';
	}
	if ((number - 2) % 10 === 0 || (number - 3) % 10 === 0 || (number - 4) % 10 === 0) {
		return 'недели';
	}
	return 'недель';
}

export function prepareSorting(sort) {
	let sortPath = '';
	Object.entries(sort).forEach(([key, value]) => {
		sortPath += `${key}=${value};`;
	});
	return sortPath;
}

export function prepareFilter(filter) {
	let filterPath = '';
	Object.entries(filter).forEach(([key, value]) => {
		filterPath += `${key}=${value};`;
	});
	return filterPath;
}

export function isBetweenDateTimes(from, to) {
	let d = new Date();
	let now = d.getTime();
	const tzOffset = d.getTimezoneOffset();

	const timeFrom = new Date(from).getTime() - tzOffset * 60 * 1000;
	const timeTo = new Date(to).getTime() - tzOffset * 60 * 1000;
	now -= tzOffset * 60 * 1000;
	return ((now > timeFrom) && (now < timeTo))
}
