import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import uniqid from 'uniqid';
import eye from '../../../../img/eye.png'
import hiddenEye from '../../../../img/hidden-eye.png'
import BC365Service from "../../../../services/beautyclub365";
import {isRussianNumber, isValidEmail} from "../../../../shared/validations";
import "./Input.css";

export default Input = (props) => {
	const {
		labelText,
		type,
		placeholder,
		required = false,
		errorText = '',
		notice = '',
		width = '400px',
		setValue,
		phoneValidation = false,
		activateButton,
		setPhoneFieldConfirmed,
		existsError,
		setExistsError,
		inputValue,
		isEmailFilled,
		emailValue = ''
	} = props;	
	let waitingSecinds = 60;

	const [showPassword, setShowPassword] = useState(false);
	const [isRusPhone, setRusPhone] = useState(false);
	const [currentValue, setCurrentValue] = useState('');
	const [isWaitingCode, setIsWaitingCode] = useState(false);
	const [wantConfirmCode, setWantConfirmCode] = useState(false);
	const [currentCode, setCurrentCode] = useState('');
	const [phoneConfirmed, setPhoneConfirmed] = useState(false);
	const [emailFilled, setEmailFilled] = useState(false);
	const [invalidPhoneCode, setInvalidPhoneCode] = useState(false);
	const [canSendAgain, setCanSendAgain] = useState(true);
	const [timer, setTimer] = useState();
	const [seconds, setSeconds] = useState(waitingSecinds);
	const [timerInterval, setTimeInterval] = useState();
	const [visualValue, setVisualValue] = useState('');

	useEffect(() => {
		if (typeof inputValue !== 'undefined' && inputValue !== '') {
			setCurrentValue(inputValue);
			setValue(inputValue);
			setVisualValue(inputValue);
		}
	}, []);

	const eyeIcon = (showPassword) ? hiddenEye : eye;
	const actionPassword = () => {
		setShowPassword(!showPassword);
	};
	const typeField = (showPassword) ? 'text' : type;

	const id = uniqid();
	const isPassword = type === 'password';
	const existsNotice = notice !== '';

	const updateValue = (e) => {
		const value = e.target.value;
		if (value !== '') {
			setExistsError(false);
		}
		setValue(value);
		setVisualValue(value);
		if (phoneValidation) {
			const isRusPhone = isRussianNumber(value);
			activateButton(!isRusPhone);
			setRusPhone(isRusPhone);
			setCurrentValue(value);
		}
	};

	const updatePhoneCode = (e) => {
		const code = e.target.value;
		setCurrentCode(code);
		if (code !== '') {
			setWantConfirmCode(true);
		} else {
			setWantConfirmCode(false);
		}
	};

	const confirmPhone = useCallback(() => {
		const db = new BC365Service();
		db.confirmCode(currentValue, currentCode).then((answer) => {
			if (answer['is_valid']) {
				setPhoneConfirmed(true);
				setInvalidPhoneCode(false);
				clearTimeout(timer);
				clearInterval(timerInterval);
				activateButton(true);
				setPhoneFieldConfirmed(true);
			} else {
				setInvalidPhoneCode(true);
			}
		});
	}, [
		isRusPhone,
		setIsWaitingCode,
		isWaitingCode,
		currentCode,
		setInvalidPhoneCode,
		invalidPhoneCode,
		activateButton,
	]);

	const sendCode = useCallback(() => {
		const db = new BC365Service();
		db.sendCode(currentValue).then((answer) => {
			setSeconds(60);
			setIsWaitingCode(true);
			setCanSendAgain(false);

			const interval = setInterval(() => {
				setSeconds(waitingSecinds--);
				if (waitingSecinds < 1) {
					clearInterval(interval);
				}
			}, 1000);

			const timer = setTimeout(() => {
				setCanSendAgain(true);
				clearTimeout(timer);
				clearInterval(timerInterval);
			}, 60000);

			setTimer(timer);
			setTimeInterval(interval);
		});
	}, [
		isRusPhone,
		setIsWaitingCode,
		isWaitingCode,
		setTimer,
		setTimeInterval,
	]);

	const verifyPhoneByTelegram = useCallback(() => {
    	if (!isValidEmail(emailValue)) {
			return;
		}

		const db = new BC365Service();
		db.verifyPhoneByTelegram(emailValue).then((answer) => {
			setSeconds(60);
			setIsWaitingCode(true);
			setCanSendAgain(false);
			setWantConfirmCode(true);

			const interval = setInterval(() => {
				setSeconds(waitingSecinds--);
				if (waitingSecinds < 1) {
					clearInterval(interval);
				}
			}, 1000);

			const timer = setTimeout(() => {
				setCanSendAgain(true);
				clearTimeout(timer);
				clearInterval(timerInterval);
			}, 60000);

			setTimer(timer);
			setTimeInterval(interval);

			window.open(answer.redirect_url, '_blank');
		});
	}, [
		emailFilled,
		isRusPhone,
		setIsWaitingCode,
		isWaitingCode,
		setTimer,
		setTimeInterval,
		emailValue
	]);

	const checkVerificationPhoneByTelegram = useCallback(() => {
		const db = new BC365Service();
		db.checkVerificationPhoneByTelegram(emailValue).then((answer) => {
			if (answer.result === true) {
				setIsWaitingCode(false);
				setCanSendAgain(false);
				setPhoneConfirmed(true);
			} else {
				setInvalidPhoneCode(true);
			}
		});
	}, [
		emailFilled,
		isRusPhone,
		setIsWaitingCode,
		isWaitingCode,
		setTimer,
		setTimeInterval,
		emailValue
	]);

	return (
		<div className="inputWrapper" >
			<label 
				htmlFor={'input-' + id}
				className="inputLabel" 
			>
				{labelText}
				{required && <span className="inpurRequired">*</span>}
			</label>
			{isPassword && 
				<div className="eyeWrapper" style={{width}}>
					<input 
						type={typeField} 
						placeholder={placeholder} 
						id={'input-' + id}  
						className={(existsError && errorText !== '') ? "inputText inputFieldError" : "inputText"}
						style={{width}}
						onChange={updateValue}
						value={visualValue ?? null}
					/>
					<span className="inpurEye">
						<img src={eyeIcon} onClick={actionPassword} />
					</span>
					{(existsError && errorText !== '') && <span className="inputError" style={{width: width}}>{errorText}</span>}
					{existsNotice && <span className="inputNotice">{notice}</span>}
				</div>
			}
			{!isPassword && 
				<div className="eyeWrapper" style={{width}}>
					<input 
						type={type} 
						placeholder={placeholder} 
						id={'input-' + id}  
						className={(existsError && errorText !== '') ? "inputText inputFieldError" : "inputText"}
						style={{width}}
						onChange={updateValue}
						value={visualValue ?? null}
					/>
					{
						phoneValidation && isRusPhone && isWaitingCode && !phoneConfirmed &&
						<input 
							type="text"
							placeholder="Введите код"
							id={'input-code-' + id}  
							className={invalidPhoneCode ? "inputText inputFieldError" : "inputText"}
							style={{width}}
							onChange={updatePhoneCode}
						/>
					}
					{
						phoneValidation && !isRusPhone && canSendAgain && !phoneConfirmed &&
						<button
							className={isEmailFilled ? "phoneConfirmButton" : "phoneConfirmButton inputButtonDisabled"}
							onClick={isEmailFilled ? verifyPhoneByTelegram : "javascript:void(0);"}
							disabled={!isEmailFilled}
							style={{width}}
						>
							Подтвердить через Telegram
						</button>
					}
					{!phoneConfirmed && !isRusPhone && invalidPhoneCode && <span className="inputErrorCode">Проверка не пройдена. Попробуйте ещё раз.</span>}
					{
						phoneValidation && !isRusPhone && !canSendAgain && wantConfirmCode && !phoneConfirmed &&
						<button
							className="phoneConfirmButton"
							onClick={checkVerificationPhoneByTelegram}
							style={{width}}
						>
							Проверить
						</button>
					}
					{invalidPhoneCode && isRusPhone && !phoneConfirmed && <span className="inputErrorCode">Введённый код неправильный</span>}
					{
						phoneValidation && isRusPhone && canSendAgain && !wantConfirmCode && !phoneConfirmed &&
						<span className="phoneConfirmButton" onClick={sendCode} style={{width}}>Подтвердить через смс</span>
					}
					{
						phoneValidation && isRusPhone && !canSendAgain && !wantConfirmCode && !phoneConfirmed &&
						<>
							<span className="phoneWaitnigNext" style={{width}}>Код отправлен</span>
							<span className="phoneWaitnigNextText">На указанный номер телефона был выслан код подтверждения. Повторная отправка через {seconds} сек.</span>
						</>
					}
					{
						phoneValidation && isRusPhone && wantConfirmCode && !phoneConfirmed &&
						<span className="readyConfirmPhone" onClick={confirmPhone} style={{width}}>Подтвердить телефон</span>
					}
					{
						phoneValidation && isRusPhone && phoneConfirmed &&
						<span className="confirmedPhone" onClick={confirmPhone} style={{width}}>Телефон успешно подтверждён</span>
					}
					{(existsError && errorText !== '') && <span className="inputError">{errorText}</span>}
					{existsNotice && <span className="inputNotice">{notice}</span>}
				</div>
			}
		</div>
	);
}
