import React, { useState } from "react";
import BC365Service from "../../../../services/beautyclub365";
import { useCookies } from 'react-cookie';
import { useUserContext } from "../../../../context/UserContext";
import { validateRegister, validateAuth } from "../../../../shared/validations";
import { usePopupContext } from "../../../../context/PopupContext";
import AllProducts from "../../../Forms/AllProducts/AllProducts";
import WaitingTrialAnswer from "../../../Forms/WaitingTrialAnswer/WaitingTrialAnswer";
import TrialBadAnswer from "../../../Forms/TrialBadAnswer/TrialBadAnswer";
import Certificates from "../../Certificates/Certificates";
import Buy from "../../../Forms/Buy/Buy";
import JustRegister from "../../../Forms/JustRegister/JustRegister";
import { YM_KEY, PROXY_URL } from "../../../../shared/constants";
import "./Button.css";
import PaymentForm from '../../AllProducts/PaymentForm';
import BasicTariffPayment from '../../AllProducts/BasicTariffPayment'
import AnnettTariffPayment from '../../AllProducts/AnnettTariffPayment'
import AllTariffPayment from '../../AllProducts/AllTariffPayment';

export default Button = ({ selectedTariff, ...props }) => {
	const {
		type,
		value,
		disabled = false,
		fields,
		setIsWaitingAnswer,
		method,
		validate,
		phoneFieldConfirmed,
		existsErrors,
		setAnswerText,
	} = props;
	window.dataLayer = window.dataLayer || [];

	const [_, setCookie] = useCookies(['PHPSESSID']);
	const { 
		setUserProfile, 
		userProfile, 
		basketData, 
		orderData, 
		isCertificate, 
		setIsCertificate,
		setShowTestDrive,
		setUserCanUseTrial,
		userCanUseTrial,
		setPreUserLogin,
		justBuyCertificate,
		isCourse,
		setUserStatus,
		typeOfProduct,
	} = useUserContext();

	const { 
		setActive, 
		setContent, 
		setNeedContinue, 
		fromTrialPopup, 
		setAnonymousCanGetTrial,
		typePopup,
	} = usePopupContext();

	
	const login = () => {
		const {login, password} = fields;
		const errors = validateAuth(fields);
		validate(errors);

		const countErrors = Object.values(errors).filter((value) => value !== '').length;
		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}

		if (countErrors === 0) {
			setIsWaitingAnswer(true);
			const db = new BC365Service();
			db.login(login, password).then((answer) => {
				if (answer['is_authorized']) {
					dataLayer.push({'event': 'vhod'});
					setUserProfile({
						fio: `${answer['user_data']['name']}`, 
						email: answer['user_data']['email'], 
						avatar: answer['user_data']['photo'], 
						login: answer['user_data']['login'], 
						user_id: answer['user_data']['user_id'], 
						token: answer.session_id, 
						online: true,
					});
					setCookie('PHPSESSID', answer.session_id, { path: '/'});
					// hide get trial
					if (answer['user_data']['user_id'] > 0) {
						db.canGetTrial().then((answer) => {
							const { result } = answer;
							setShowTestDrive(result);
							setUserCanUseTrial(result);
						});
					}
					setUserStatus(2);
				} else {
					const errors = {
						be: 'Логин или пароль неправильные',
					};
					validate(errors);
					existsErrors(true);
					setUserStatus(1);
				}
				setIsWaitingAnswer(false);
			});
		}
	};

	const register = () => {
		const errors = validateRegister(fields);
		validate(errors);
		const { name, secondName, email, phone, password, repeatPassword} = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;

		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}

		if (countErrors === 0) {
			setIsWaitingAnswer(true);
			const db = new BC365Service();
			const isConfirmedPhone = (phoneFieldConfirmed) ? 'Y' : 'N';
			db.register(name, secondName, email, phone, password, isConfirmedPhone).then((answer) => {
				if (answer['is_registered']) {

					const db = new BC365Service();
					db.sendEventFromPopup(
						email,
						'Вход/Регистрация|registration',
						'',
						'',
						phone,
						secondName
					).then((answer) => {
						console.log(answer);
					});

					setUserProfile({
						fio: `${answer['user_data']['name']} ${answer['user_data']['last_name']}`, 
						email: answer['user_data']['email'], 
						avatar: answer['user_data']['photo'], 
						login: answer['user_data']['login'], 
						token: answer.session_id, 
						user_id: answer['user_data']['user_id'],
						online: true,
					});
					// hide get trial
					if (answer['user_data']['user_id'] > 0) {
						db.canGetTrial().then((answer) => {
							const { result } = answer;
							setShowTestDrive(result);
							setUserCanUseTrial(result);
						});
					}
					setCookie('PHPSESSID', answer.session_id, { path: '/'});
					setUserStatus(2);
					window.location.href = '/personal/subscription-management/';
				} else {
					const errors = {
						be: answer['error'],
					};
					validate(errors);
					existsErrors(true);
					setUserStatus(1);
				}
				if (answer['is_registered'])
				dataLayer.push({'event': 'registr1'});
				window.location.href = PROXY_URL + '/personal/subscription-management/';
				setIsWaitingAnswer(false);
			});
		}
	};

	const forgot = () => {
		const errors = validateRegister(fields);
		validate(errors);
		const { email } = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;

		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}
		if (countErrors === 0) {
			setIsWaitingAnswer(true);
			const db = new BC365Service();
			db.forgotPassword(email).then((answer) => {
				if (answer['error'] === '') {
					dataLayer.push({'event': 'password'});
					setAnswerText(answer['answer']);
				} else {
					const errors = {
						be: answer['error'],
					};
					validate(errors);
					existsErrors(true);
				}
				setIsWaitingAnswer(false);
			});
		}
	};

	const checkEmail = () => {
		const errors = validateRegister(fields);
		validate(errors);
		const { email } = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;
		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}

		if (countErrors === 0) {
			const db = new BC365Service();
			let tariff = '';
			if (typePopup.includes('|')) {
				const tr = typePopup.split('|');
				tariff = tr[1];
			}
			db.sendEventFromPopup(
				email,
				typePopup,
				'',
				tariff
			).then((answer) => {
				console.log(answer);
			});

			setPreUserLogin(email);
			setIsWaitingAnswer(true);
			
			db.existsByEmail(email).then((answer) => {
				if (answer['user_id'] > 0) {
					const userId = answer['user_id'];
					// only if coming from trial popup
					if (fromTrialPopup) {
						db.canGetTrial().then((answer) => {
							const { result } = answer;
							setShowTestDrive(result);
							setUserCanUseTrial(result);

							// and set trial
							setContent(<WaitingTrialAnswer />);
							// add trial and redirect to personal area
							db.setTrialByUserId(userId).then((answer) => {
								const { result } = answer;
								if (result) {
									window.location.assign('/personal/'); 
								} else {
									setAnonymousCanGetTrial(false);
									setContent(<TrialBadAnswer />);
									const showBadTrailAnswer = setTimeout(() => {
										setContent('');
										setActive(false);
										clearTimeout(showBadTrailAnswer);
									}, 3000);
								}
							});
						});
					} else {
						dataLayer.push({'event': 'mail'})
						existsErrors(false);
						setIsCertificate(false);
						switch (selectedTariff) {
							case 'basic':
								setContent(<BasicTariffPayment />);
								break;
							case 'annett':
								setContent(<AnnettTariffPayment />);
								break;
							case 'all':
								setContent(<AllTariffPayment />);
								break;
							default:
								setContent(<PaymentForm/>);
								break;
						}
						setNeedContinue(true);
						setUserProfile({
							...userProfile,
							login: answer['user_login'],
							user_id: answer['user_id'],
							email: answer['email'],
							fio: answer['fio'],
						});
					}
				} else {
					existsErrors(false);
					setContent(<JustRegister selectedTariff={selectedTariff} />);
					setNeedContinue(true);
					setIsWaitingAnswer(false);
				}
			});
		}
	};

	const widgetPayselection = (options) => {
		let widget = new pw.PayWidget();

		options.push({
				// Варианты ключей которые могут приходить по колбекам:
				// для onSuccess -> PAY_WIDGET:TRANSACTION_SUCCESS
				// для onError -> PAY_WIDGET:TRANSACTION_FAIL, PAY_WIDGET:CREATE_NETWORK_ERROR, PAY_WIDG:CREATE_BAD_REQUEST_ERROR
				onSuccess: function (res) {
					console.log("onSuccess from shop", res);
					//window.location.href = response.return_url;
				},
				onError: function (res) {
					console.log("onFail from shop");
					console.log(res);
					//window.location.href = response.return_url;
				},
				onClose: function (res) {
					console.log("onClose from shop", res);
				},
			}
		);

		widget.pay(
			options[0],
			options[1],
			options[2]
		);
	}

	const widgetPayinPayout = (options) => {
		let payinpayoutForm = document.getElementById("payinpayoutForm");
		if (payinpayoutForm !== null)
			payinpayoutForm.remove();

		let formData = JSON.parse(options);
		//console.log(formData);
		let form = document.createElement('form');
		form.id = 'payinpayoutForm';
		form.method = 'POST';
		form.action = formData.url;

		for (const name in formData) {
			if (name === 'url')
				continue;

			let input = document.createElement('input');
			input.type = 'hidden';
			input.value = formData[name];
			input.name = name;

			form.append(input);
		}

		document.body.append(form);

		form.submit();
	}

	const checkEmailCertificate = () => {
		const errors = validateRegister(fields);
		validate(errors);
		const { email } = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;

		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}
		if (countErrors === 0) {
			const db = new BC365Service();
			db.sendEventFromPopup(
				email,
				typePopup
			).then((answer) => {
				console.log(answer);
			});

			//console.log('checking email');
			setIsWaitingAnswer(true);
			
			db.existsByEmail(email).then((answer) => {
				if (answer['user_id'] > 0) {
					existsErrors(false);
					setIsCertificate(true);
					setContent(<Certificates/>);
					setNeedContinue(true);
					setUserProfile({
						...userProfile,
						login: answer['user_login'],
						user_id: answer['user_id'],
						email: answer['email'],
						fio: answer['fio'],
					});
				} else {
					existsErrors(false);
					setContent(<JustRegister selectedTariff={selectedTariff} />);
					setNeedContinue(true);
					setIsWaitingAnswer(false);
				}
			});
		}
	};

	const registerAndBuy = () => {
		dataLayer.push({'event': 'registr2'})
		const errors = validateRegister(fields);
		validate(errors);
		const { name, secondName, email, phone, password, repeatPassword} = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;

		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}

		if (countErrors === 0) {
			const db = new BC365Service();
			const { name } = basketData;
			db.sendEventFromPopup(
				email,
				'Брошены в корзину',
				name
			).then((answer) => {
				console.log(answer);
			});

			if (!fromTrialPopup) {
				setIsWaitingAnswer(true);
			}
			
			const isConfirmedPhone = (phoneFieldConfirmed) ? 'Y' : 'N';
			ym(YM_KEY,'reachGoal',' regtopc');
			db.register(name, secondName, email, phone, password, isConfirmedPhone).then((answer) => {
				if (answer['is_registered']) {
					if (!justBuyCertificate && answer['user_data']['user_id'] > 0) {
						const userId = answer['user_data']['user_id'];
						if (fromTrialPopup) {
							db.canGetTrial().then((answer) => {
								const { result } = answer;
								setShowTestDrive(result);
								setUserCanUseTrial(result);
	
								// and set trial
								setContent(<WaitingTrialAnswer />);
								// add trial and redirect to personal area
								db.setTrialByUserId(userId).then((answer) => {
									const { result } = answer;
									if (result) {
										window.location.assign('/personal/'); 
									} else {
										setAnonymousCanGetTrial(false);
										setContent(<TrialBadAnswer />);
										const showBadTrailAnswer = setTimeout(() => {
											setContent('');
											setActive(false);
											clearTimeout(showBadTrailAnswer);
										}, 3000);
									}
								});
							});
						} else {
							switch (selectedTariff) {
								case 'basic':
									setContent(<BasicTariffPayment />);
									break;
								case 'annett':
									setContent(<AnnettTariffPayment />);
									break;
								case 'all':
									setContent(<AllTariffPayment />);
									break;
								default:
									setContent(<PaymentForm/>);
									break;
							}
							setUserProfile({
								fio: `${answer['user_data']['name']} ${answer['user_data']['last_name']}`, 
								email: answer['user_data']['email'], 
								avatar: answer['user_data']['photo'], 
								login: answer['user_data']['login'], 
								user_id: answer['user_data']['user_id'], 
								token: answer.session_id, 
								online: true,
							});
						}
					} else {
						setContent(<Certificates/>);
						setUserProfile({
							fio: `${answer['user_data']['name']} ${answer['user_data']['last_name']}`, 
							email: answer['user_data']['email'], 
							avatar: answer['user_data']['photo'], 
							login: answer['user_data']['login'], 
							user_id: answer['user_data']['user_id'], 
							token: answer.session_id, 
							online: true,
						});
					}
					setCookie('PHPSESSID', answer.session_id, { path: '/'});
				} else {
					const errors = {
						be: answer['error'],
					};
					validate(errors);
					existsErrors(true);
				}
				setIsWaitingAnswer(false);
			});
		}
	};

	const buy = () => {
		const db = new BC365Service();
		const { name } = basketData;
		const { email } = userProfile;
		db.sendEventFromPopup(
			email,
			'Брошенная корзина',
			name,
		).then((answer) => {
			console.log(answer);
		});

		dataLayer.push({'event': 'begin_checkout'})
		setActive(true);
		setContent(<Buy />);
	};

	const finish = () => {
		setIsWaitingAnswer(true);
		const {user_id, fio, email, phone} = userProfile;
		const {product_id} = basketData;
		const {paysystem} = orderData;
		const db = new BC365Service();

		if (isCertificate) {
			ym(YM_KEY,'reachGoal','ORDER_sert');
			db.createOrderCertificate(user_id, Number(product_id), fio, email, phone, paysystem).then((answer) => {
				setActive(false);

				if (['CLOUDPAYMENTS', 'CLOUDPAYMENTS_KZ'].includes(paysystem)) {
					const order = answer['res']['order'];
					// const return_url = answer['res']['return_url'];

					let cpOptions = {
						...orderData,
					};
					cpOptions.data['order'] = order;
					cpOptions.invoiceId = order;
					cpOptions.description = `Заказ №${order} ${cpOptions.accountId}`;

					const widget = new cp.CloudPayments();
					widget.pay('charge',
						cpOptions,
						{
							onSuccess: function (options) { // success
								//console.log(options);
							},
							onFail: function (reason, options) { // fail
								//console.log(options);

							},
							onComplete: function (paymentResult, options) {
								//console.log(options);
							}
						}
					);
				}

				if (paysystem === 'PAYSELECTION')
					widgetPayselection(answer['res']['options']);

				if (paysystem === 'PAYINPAYOUT')
					widgetPayinPayout(answer['res']['options']);

				if (['TINKOFF', 'TINKOFF_SBP', 'SBERPAY'].includes(paysystem))
					window.location.href = answer['res']['url'];

				if (['PRODAMUS'].includes(paysystem))
					window.location.href = answer['res']['pay_url'];

				setIsWaitingAnswer(false);
			});
		} else {
			ym(YM_KEY,'reachGoal','ORDER');
			// Step 2. Sales funnel (start payment process)
			db.startPayment({
				first_name: fio,
				email,
				product_id,
			}).then((answer) => {
				//console.log(answer);
			});

			if (isCourse) {
				const type = (typeOfProduct === 1) ? 'course' : 'marathon';
				db.createOrderCommon(user_id, Number(product_id), fio, email, phone, paysystem, type).then((answer) => {
					setActive(false);
	
					if (['CLOUDPAYMENTS', 'CLOUDPAYMENTS_KZ'].includes(paysystem)) {
						const order = answer['res']['order'];
						// const return_url = answer['res']['return_url'];
	
						let cpOptions = {
							...orderData,
						};
						cpOptions.data['order'] = order;
						cpOptions.invoiceId = order;
						cpOptions.description = `Заказ №${order} ${cpOptions.accountId}`;
						const widget = new cp.CloudPayments();
						widget.pay('charge',
							cpOptions,
							{
								onSuccess: function (options) { // success
									//console.log(options);
								},
								onFail: function (reason, options) { // fail
									//console.log(options);
								},
								onComplete: function (paymentResult, options) {
									const { message, success } = paymentResult;
									if (success) {
										// Step 3. Sales funnel (success payment)
										db.successPayment({
											email,
											product_id,
											first_name: fio,
										}).then((answer) => {
											//console.log(answer);
										});
									} else {
										// Step 4. Sales funnel (failed payment)
										db.failedPayment({
											email,
											close_reason: message,
											product_id,
											first_name: fio,
										}).then((answer) => {
											//console.log(answer);
										});
									}
								}
							}
						);
					}
	
					if (paysystem === 'PAYSELECTION') {
						widgetPayselection(answer['res']['options']);
					}
					if (paysystem === 'PAYINPAYOUT') {
						widgetPayinPayout(answer['res']['options']);
					}

					if (['TINKOFF', 'TINKOFF_SBP', 'SBERPAY', 'PRODAMUS'].includes(paysystem))
						window.location.href = answer['res']['url'];
	
					setIsWaitingAnswer(false);
				});
			} else {
				db.createOrder(user_id, Number(product_id), paysystem).then((answer) => {
					setActive(false);
	
					if (['CLOUDPAYMENTS', 'CLOUDPAYMENTS_KZ'].includes(paysystem)) {
						const order = answer['res']['order'];
						// const return_url = answer['res']['return_url'];
	
						let cpOptions = {
							...orderData,
						};
						cpOptions.data['order'] = order;
						cpOptions.invoiceId = order;
						cpOptions.description = `Заказ №${order} ${cpOptions.accountId}`;
	
						const widget = new cp.CloudPayments();
						widget.pay('charge',
							cpOptions,
							{
								onSuccess: function (options) { // success
									//console.log(options);
								},
								onFail: function (reason, options) { // fail
									//console.log(options);
								},
								onComplete: function (paymentResult, options) {
									const { message, success } = paymentResult;
									if (success) {
										// Step 3. Sales funnel (success payment)
										db.successPayment({
											email,
											product_id,
											first_name: fio,
										}).then((answer) => {
											//console.log(answer);
										});
									} else {
										// Step 4. Sales funnel (failed payment)
										db.failedPayment({
											email,
											close_reason: message,
											product_id,
											first_name: fio,
										}).then((answer) => {
											//console.log(answer);
										});
									}
								}
							}
						);
					}
	
					if (paysystem === 'PAYSELECTION') {
						widgetPayselection(answer['res']['options']);
					}
					if (paysystem === 'PAYINPAYOUT') {
						widgetPayinPayout(answer['res']['options']);
					}

					if (['TINKOFF', 'TINKOFF_SBP', 'SBERPAY', 'PRODAMUS'].includes(paysystem))
						window.location.href = answer['res']['url'];
	
					setIsWaitingAnswer(false);
				});
			}
		}
	};

	const sendPromo = () => {
		const errors = validateRegister(fields);
		validate(errors);
		const { email } = fields;
		const countErrors = Object.values(errors).filter((value) => value !== '').length;

		if (countErrors) {
			existsErrors(true);
		} else {
			existsErrors(false);
		}
		if (countErrors === 0) {
			setIsWaitingAnswer(true);
			console.log('0 errors send promo');
			const db = new BC365Service();
			db.sendPromoToFriend(email).then((answer) => {
				if (answer['error'] === '') {
					setAnswerText(answer['answer']);
				} else {
					const errors = {
						be: answer['error'],
					};
					validate(errors);
					existsErrors(true);
				}
				setIsWaitingAnswer(false);
			});
		}
	};

	let callback;
	if (method === 'login') {
		callback = login;
	}
	if (method === 'register') {
		callback = register;
	}
	if (method === 'forgot') {
		callback = forgot;
	}
	if (method === 'check-email') {
		callback = checkEmail;
	}
	if (method === 'check-email-certificate') {
		callback = checkEmailCertificate;
	}
	if (method === 'register-and-buy') {
		callback = registerAndBuy;
	}
	if (method === 'buy') {
		callback = buy;
	}
	if (method === 'finish') {
		callback = finish;
	}
	if (method === 'sendPromo') {
		callback = sendPromo;
	}

	return (
		<input 
			type={type} 
			value={value} 
			disabled={disabled}
			className={disabled? "inputButton inputButtonDisabled" : "inputButton"}
			onClick={callback}
		/>
	);
}
