import { createContext, useContext, useMemo, useState, useEffect } from "react";

const initialState = {
  isActive: false,
  setActive: () => {},
  config: {
	'button_color': '',
	'button_link': '',
	'button_text': '',
	'discount_color': '',
	'discount_description': '',
	'discount_text': '',
	'start_timer': 0,
	'end_timer': 0,
  },
  setConfig: () => {},
};

const MarketContext = createContext(initialState);

export const MarketProvider = ({ children }) => {

	const [isActive, setActive] = useState(initialState.isActive);
	const [config, setConfig] = useState(initialState.config);

	useEffect(() => {
		setConfig(config);
	}, [
		/*config,
		setConfig,*/
	]);

  	const value = useMemo(() => {
		return {
			isActive,
			setActive,
			config,
			setConfig,
		};
  }, [
	isActive,
	setActive,
	config,
	setConfig,
  ]);

  return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>;
};

export const useMarketContext = () => {
  return useContext(MarketContext);
};
