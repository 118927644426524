import React, { useState } from 'react';
import { getImagesPaths } from '../../shared/utils';
import { usePopupContext } from '../../context/PopupContext';
import { useUserContext } from '../../context/UserContext';
import CheckEmail from '../Forms/CheckEmail/CheckEmail';
import { useEffect } from 'react';
import {isMobile} from 'react-device-detect';
import { TRY_SUBSON_5_DAYS_POPUP_TIMER } from '../../shared/constants';
import { TRY_SUBSON_5_DAYS_POPUP_TIMER_20 } from '../../shared/constants';
import { PROXY_URL } from "../../shared/constants";

import SendPromo from "../Forms/SendPromo/SendPromo";

import './HeaderInfo.css';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { Autoplay, FreeMode, Navigation, Thumbs } from 'swiper/modules';
import Certificates from "../Forms/Certificates/Certificates";
import SecondButtonPopupContent from "../PhotoCarousel/SecondButtonPopupContent/SecondButtonPopupContent"


function HeaderInfo(props) {
  window.dataLayer = window.dataLayer || [];

  const { 
    headerContent, 
    x1Props, 
    bannersConfig, 
  } = props;

  let {
    banners,
    config,
    banners_preview,
    second_buttons,
  } = bannersConfig;

  const {
    autoplay,
    time,
    advertise_text,
  } = config;

  // prepare Swipper config
  let SwipperTime = 3000;
  let SwipperModules = [FreeMode, Navigation, Thumbs];
  if (autoplay) {
    SwipperModules.push(Autoplay);
    SwipperTime = parseInt(time) * 1000;
  }

  let listBanners = (isMobile) ? banners['mobile'] : banners['desktop'];

  listBanners = listBanners.map((banner, index) => {
    if (!second_buttons[index])
      return banner;

    return {
      ...banner,
      second_button: second_buttons[index]
    };
  });

  listBanners = listBanners.filter((currentBanner) => currentBanner.show);
  banners_preview = banners_preview.filter((currentBanner) => currentBanner.show);

  const countPreview = (isMobile) ? 3 : 6;

  const { butterfly, rectangle14, rectangle15 } = x1Props;
  const { images, subMainText, imagesOpt } = headerContent;
  const [ timerId, setTimerId ] = useState(0);
  let [ timerChain, setTimerChain ] = useState({
    0: false,
    1: false,
  });
  const [ countShowedTimerPopups, setCountShowedTimerPopups ] = useState(0);
  let { mainText, headText, headTextWithTimer, timer } = headerContent;
  let bannerText = headText;
  if (timer > 0 && headTextWithTimer !== '' && typeof headTextWithTimer !== 'undefined') {
    const currentTime = Math.ceil(Date.now() / 1000);
    bannerText = (currentTime > timer) ? headTextWithTimer : headText;
  }

  // prepare main text
  mainText =
    mainText && mainText.includes('#SUB_TEXT#')
      ? mainText.replaceAll(
          '#SUB_TEXT#',
          `<span class="span1">${subMainText} </span>`
        )
      : mainText;

  // prepare images
  let contentImages;
  if (imagesOpt?.length > 0) {
    contentImages = getImagesPaths(imagesOpt);
  } else {
    contentImages = getImagesPaths(images);
  }

  const { 
    setActive, 
    setContent,
    wasClosedPopup,
    wasOpenedPopupBefore,
    anonymousCanGetTrial,
    setType,
  } = usePopupContext();

  const { 
      userProfile, 
      setIsCertificate, 
      userCanUseTrial, 
      showTestDrive, 
  } = useUserContext();

  const { online } = userProfile;

  const joinToUs = () => {
    dataLayer.push({'event': 'join'})
    setType('click_button_site');
    const { online } = userProfile;
    setActive(true);
    if (online) {
      /*if (userCanUseTrial) {
        setIsCertificate(false);
        setContent(<AllProducts />);
      }*/
      window.location.assign('/personal/subscription-management/');
    } else {
      setContent(<CheckEmail />);
    }
  };

  const setTimerPopup = (sec) => {
    const countWorkedPopups = Object.values(timerChain).filter((el) => el).length;
    if (
      (wasClosedPopup && !wasOpenedPopupBefore) // after another popup
      || (countWorkedPopups === 0 && (!wasClosedPopup && !wasOpenedPopupBefore)) // after init app
      ) {
      const showPopupTimer = setTimeout(() => {
        // showTrialPopup();
        timerChain[countShowedTimerPopups] = true;
        setTimerChain({
          ...timerChain,
        });
        const nextTimer = countShowedTimerPopups + 1;
        setCountShowedTimerPopups(nextTimer);
        clearTimeout(showPopupTimer);
      }, sec);
      setTimerId(showPopupTimer);
    } else {
      clearTimeout(timerId);
    }
  };

  const canShowTimerPopupByStep = (step) => {
    const steps = Object.values(timerChain);
    const countPrevDone = steps.filter((el) => el).length;
    if (!timerChain[step] && countPrevDone === step) {
      return true;
    }
    return false;
  };

  const secondButtonShowPopup = (content) => {
    setActive(true);
    setContent(<SecondButtonPopupContent content={content} />);
  };

  const sendPromoToUser = (content) => {
    setActive(true);
    setContent(content);
  };

  useEffect(() => {
    const { online } = userProfile;
    // if (showTestDrive && ((online && userCanUseTrial) || !online)) {
    if (showTestDrive && !online && anonymousCanGetTrial) {  
      if (canShowTimerPopupByStep(0)) {
        setTimerPopup(TRY_SUBSON_5_DAYS_POPUP_TIMER);
      }
      if (canShowTimerPopupByStep(1)) {
        setTimerPopup(TRY_SUBSON_5_DAYS_POPUP_TIMER_20);
      }
    }
  }, [
    showTestDrive,
    userCanUseTrial,
    wasClosedPopup,
    wasOpenedPopupBefore,
    timerChain,
    userProfile,
    anonymousCanGetTrial,
  ]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [useLoop, setUseLoop] = useState(listBanners.length > 1);

  ///////////
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isTestMod = urlParams.get('test') === 'y';
  ///////////

  return (
    <>
      <div className="x1">
        <div className="rectangle-13"></div>
          {listBanners.length > 0 && (
          <>
          <Swiper
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            autoplay= {{
              delay: SwipperTime,
            }}
            loop={useLoop}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={SwipperModules}
            className="mainSwipper"
          >
          {listBanners.map(({
            image,
            text,
            button,
            second_button
          }, index) => { 
            
            let color = '#ff059b';
            let link = '';
            let text_button = '';
            let text_color = '#fff';
            let second_button_text = '';
            let second_button_content = '';
            let go_out = false;
            if (typeof button !== 'undefined') {
              color = button?.color;
              link = button?.link;
              text_button = button?.text_button || '';
              text_color = button?.text_color;
              let go_link = button?.link_go?.toString() || '';
              go_out = go_link !== '';
            }
            if (typeof second_button !== 'undefined') {
              second_button_text = second_button?.button_text || '';
              second_button_content = second_button?.banner_content;
            }

            return (
              
            <SwiperSlide key={index}>
              <div className='slider-wrappber'>
                <img src={`${PROXY_URL}/upload/${image}`} />
                <div className="slider-text-main" dangerouslySetInnerHTML={{ __html: text }}></div>
                {text_button !== '' && (<button
                        className="slider-button"
                        style={{color: text_color, backgroundColor: color}}
                        onClick={(e) => {
                          if (go_out) {
                            window.open(link, '_blank');
                          } else {
                            window.location.href = link
                          }
                        }}
                    >
                      {text_button}
                    </button>
                )}

                {online && second_button_text !== '' && second_button_content !== '' && (<button
                        className="slider-button second"
                        style={{color: text_color, backgroundColor: color}}
                        // onClick={e => secondButtonShowPopup(second_button_content)}
                        onClick={e => sendPromoToUser(<SendPromo />)}
                    >
                      {second_button_text}
                    </button>
                )}

                <div className="slider-advertise-text-wrapper">
                  <span className="slider-advertise-text">{advertise_text}</span>
                </div>
              </div>
              
            </SwiperSlide>
          )})}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={useLoop}
          spaceBetween={0}
          slidesPerView={countPreview}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Thumbs]}
          className="previewSwipper"
          navigation
          scrollbar={{ draggable: true }}
        >
          {banners_preview.map(({
            image,
            text,
            top,
            left,
            color,
            width,
          }, index) => { return (
            <SwiperSlide key={index}>
              <span 
                className="slider-text-preview"
                style={{
                  top,
                  left,
                  color,
                  width,
                }}
              >{text}</span>
              <img src={`${PROXY_URL}/upload/${image}`} />
            </SwiperSlide>
          )})}
        </Swiper>
          </>
        )
        }
        <div className="headerInfo-group">
          <div className="x1-first-results-block">
            <div
              className="h1-club-x1 montserrat-extra-bold-white-48px"
              id="for-who"
            >
              Круг единомышленниц, о котором вы всегда мечтали
            </div>
            <div className="frame-375">
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px" style={{height:'116px'}}>
                  Первый результат уже через 4 недели
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px" style={{height: '145px'}}>
                  Омоложение, <p style={{whiteSpace:'nowrap'}}>здоровье, спорт,</p> развитие, психология, творчество - <span style={{whiteSpace:'nowrap'}}>15+ направлений</span>
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  Вебинары, тренировки, тренинги и мастер-классы в прямом эфире
                </div>
              </div>
            </div>
            <div className="frame-374">
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  15 ТОП-экспертов на одной онлайн-платформе
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  От 3 до 7 эфиров в день: легко найти то, что будет вам по душе
                </div>
              </div>
              <div className="frame-371">
                <div className="rectangle-4"></div>
                <div className="text-125 inter-normal-mischka-22px">
                  Полезные привычки, которые останутся с вами на всю жизнь
                </div>
              </div>
            </div>
          </div>
        </div>

        <img className="rectangle-1" src={rectangle14} alt="Rectangle 14" />
        <img className="rectangle-1" src={rectangle15} alt="Rectangle 15" />
        <img className="butterfly" src={butterfly} alt="butterfly" />
      </div>
    </>
  );
}

export default HeaderInfo;
