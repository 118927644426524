import React from 'react';
import './TariffDetailPopUp.css';

function TariffDetailPopup() {
  return (
    <div className="tariffDetail-container">
      <h1 className="tariffDetail-h1">Тариф «ВСЕ ВКЛЮЧЕНО»</h1>
      <p className="tariffDetail-description">
        С этим тарифом вы легко реализуете мечту начинать утро активно! Йога,
        растяжка,бодифлекс, фитнес, цигун, славянская гимнастика, танцевальные
        практики - выбирайте любую практику, в любое время в прямом эфире или в
        записи. Все, что нужно для занятий - интернет и коврик! <br /> <br /> Но
        это не все. На этом тарифе нашего закрытого клуба вы в любой момент
        будете иметь возможность послушать наши лекции, посмотреть мастер-классы
        по астрологии, психологии, развитию памяти, нейрографике, сможете
        общаться с экспертами и единомышленниками, задавать спикерам вопросы.{' '}
        <br /> <br /> Ваша жизнь изменится! В ней появится много новых знаний,
        практических навыков, единомышленников. Возможно, это подтолкнет вас к
        собственной реализации или поможет определиться с тем, чему вы хотите
        посвятить жизнь. <br /> <br /> «Все включено» - для того, чтобы
        меняться, развиваться и быть довольной собой! <br /> <br />
      </p>
    </div>
  );
}

export default TariffDetailPopup;
