import React, { useState, useEffect, useCallback } from "react";
import BC365Service from "../../services/beautyclub365";
import { useUserContext } from "../../context/UserContext";
import "./Promocode.css";

export default Promocode = ({
  tariff,
  disabled,
}) => {
  const {
    userProfile,
    basketData,
    setBasket,
    setDefaultPrice,
    setProducts,
    } = useUserContext();

  const [coupon, setCoupon] = useState('');
  const [statusButton, setStatusButton] = useState({
    default: true,
    waiting: false,
    done: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const getTextButtonByStatus = useCallback(() => {
    if (statusButton['waiting']) {
      return 'Подождите';
    }
    if (statusButton['done']) {
      return 'Активирован';
    }
    return 'Применить';
  }, [
    statusButton,
  ]);

  const isActiveClass = useCallback(() => {
    if (statusButton['default'] && !disabled) {
      return 'promoButton';
    }
    return 'promoButton notActive';
  }, [
    statusButton,
    disabled,
  ]);

  const writeCoupon = (e) => {
    setCoupon(e.target.value);
  };

  const getDiscount = useCallback((e) => {
    // if (coupon === '') {setErrorMessage('Введите промокод')}
    if (statusButton['default']) {
      const db = new BC365Service();
      const { product_id } = basketData;
      const { user_id } = userProfile;
      setStatusButton({
        default: false,
        waiting: true,
        done: false,
      });
      db.getDiscount(coupon, product_id, user_id).then((answer) => {
        if (answer['res'] > 0) {
          const priceAfterDiscount = answer['base_price'] * 1 - answer['discount'] * 1;
          const formattedPrice = new Intl.NumberFormat('ru-RU', {currency: 'RUB' }).format(priceAfterDiscount);
          setBasket(
            {
              ...basketData,
              price_clear: priceAfterDiscount,
              price: `${formattedPrice} руб.`,
            }
          );
          setStatusButton({
            default: false,
            waiting: false,
            done: true,
          });
        } else {
          setStatusButton({
            default: true,
            waiting: false,
            done: false,
          });
        }
        return answer;
      }).then(({ res }) => {
        // discount exists
        if (res === 0) {
          setErrorMessage('Ваш промокод не действителен');
        } else {
					setErrorMessage('');
				}
        if (res > 0) {
          // get products with discounts
          db.getProducts().then((answer) => {
            if (answer["products"].length > 0) {
              setDefaultPrice(answer["products"][0].price);
              const productPairs = answer["products"].reduce(
              (result, value, index, array) => {
                if (index % 2 === 0) {
                result.push(array.slice(index, index + 2));
                }
                return result;
              },
              []
              );
              setProducts(productPairs);
            }
          });
        }
      });
    }
  }, [
    coupon,
    statusButton,
    basketData,
  ]);

  return (
    <>
    <div className="promoContainer">
      <div className="promoWrapper">
        <input id="promo" type = "text" placeholder="Введите промокод" className="promoInput" onChange={writeCoupon} />
        <span className={isActiveClass()} onClick={getDiscount}>{getTextButtonByStatus()}</span>
      </div>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
    <div className="cpWrapper2">
      <span className="cpPrice">К оплате {basketData.price}</span>
      {/* <span className="cpTarriff"> Тариф {tariff}</span> */}
    </div>
    </>
  );
}
